import { makeStyles } from '@material-ui/core/styles';
import { IData } from '..';

const useStyles = makeStyles((theme) => ({
  circle: (props: IData) => ({
    width: 12,
    height: 12,
    margin: '0px 5px',
    borderRadius: '50%',
    backgroundColor: props.color,
    background: props.gradient
      ? `linear-gradient(90deg, ${props.color} 51%, ${props.gradient} 49%)`
      : '',
  }),
}));

export default useStyles;
