import { makeStyles } from '@material-ui/core/styles';
import { IProps } from './index';

const batteryBaseColor = 'gray';
const batteryFillColor = 'rgb(125, 92, 217)';

const batteryStyles = {
  border: `2px solid ${batteryBaseColor}`,
  backgroundColor: 'black',
  width: '50%',
};

const useStyles = makeStyles((theme) => ({
  batteryContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
    marginTop: '2rem',
    width: '100%',
    maxWidth: 225,
    minWidth: 150,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
  },
  batteryTopContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
  },
  batteryTop: {
    ...batteryStyles,
    height: 5,
    borderBottom: 0,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  batteryShell: {
    ...batteryStyles,
    padding: '.5em',
    boxSizing: 'border-box',
    borderRadius: 3,
  },
  batteryBarContainer: (props: IProps) => ({
    // linear-gradient 0 percent will just fill the rest of the container
    background: `linear-gradient(to top, ${batteryFillColor} ${
      props.soc * 100
    }%, ${batteryBaseColor} 0%)`,
    position: 'relative',
  }),
  batteryBars: {
    width: '100%',
    height: 10,
    backgroundColor: 'transparent',
    border: '1px solid black',
  },
  batterySocContainer: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  batterySoc: {
    fontSize: '1.2em',
    height: 'fit-content',
  },
}));

export default useStyles;
