import WGroup from 'components/general/WGroup';
import Wizard from 'components/general/wizards/Wizard';
import { wGroupIndicesATCDH } from 'components/AgentTemplateEditView/menu/cdh';

import ConditionsSegment from './ConditionsSegment';
import OperationalModesSegment from './OperationalModesSegment';

const CDHEditBoard = () => {
  return (
    <WGroup index={wGroupIndicesATCDH.CDH}>
      <Wizard>
        <ConditionsSegment />
        <OperationalModesSegment />
      </Wizard>
    </WGroup>
  );
};

export default CDHEditBoard;
