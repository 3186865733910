import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import WGroup from 'components/general/WGroup';
import EnergyStorageWizard from './EnergyStorageWizard';

const EnergyStorageEditBoard = () => {
  return (
    <WGroup index={wGroupIndicesATPower.POWER_ES}>
      <EnergyStorageWizard />
    </WGroup>
  );
};

export default EnergyStorageEditBoard;
