import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    marginTop: 5,
    marginBottom: props.onActionClick ? 15 : 0,
    position: 'relative',

    '& div.widget-table-detail-panel': {
      paddingLeft: 30,
      paddingTop: 10,
      paddingBottom: 10,
      height: '100%',
      borderLeft: '2px solid ' + theme.palette.background.contrastText,
      minHeight: 70,
      backgroundColor: theme.palette.background.main + '99',

      '& p': {
        marginTop: 0,
      },

      '& ul': {
        padding: 0,
        marginBottom: 0,

        '& li': {
          display: 'block',
        },
      },
    },
  }),

  tableWrapper: (props) => ({
    overflow: 'hidden',
    borderRadius: 4,
    boxShadow: theme.shadows[3],
    // Workaround for Material Table that is automatically using backgroundColor: theme.palette.secondary.main
    '& > div': {
      '& > div:first-child': {
        ...(props.selection && { backgroundColor: theme.palette.primary.main }),
      },
    },
    // Similar to above comment, workaround to override color MUI is using by default
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main,
    },
  }),

  addEntityButton: {
    position: 'absolute',
    right: 15,
    bottom: -15,
    zIndex: 100,
  },

  xrayButton: {
    position: 'absolute',
    right: 5,
    top: 4,
    zIndex: 100,
    height: 30,
    width: 30,

    '& svg': {
      fontSize: 20,
      color: theme.palette.text.primary,
    },
  },

  emptyMessage: {
    fontSize: '.8rem',
  },
}));

export default useStyles;
