import { useEffect, useRef } from 'react';

/**
 * Provides an indicator that says whether the current component is mounted.
 *
 * Useful for useEffects that must cancel if the component is unmounted; and for React 18+,
 * where components must be robust against sporadic mounting/unmounting
 * @returns a function returning true if the component is mounted, false otherwise
 */
const useMountStatus = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return () => isMounted.current;
};

export default useMountStatus;
