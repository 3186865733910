import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '2em',
  },
  addSubsystemButton: {
    height: '6em',
  },
  subsystemHelperInlay: {
    '& div': {
      fontSize: 16,
    },
  },
}));

export default useStyles;
