// TODO: Remove this file, see MenuHook.ts

import Routes, { routePathsCommon } from 'routes';
import { ITEMS, SUB_ITEMS, TITLE } from 'config';
import { ISubMenu, IMenu } from 'components/general/types';

const { ANALYZE, ATTITUDE, EDIT, FSS, PLAYBACK, TS, WS } = routePathsCommon;

const BODY_FRAME_VECTORS = 'body-frame-vectors';
const CON_OPS = 'con-ops';
const CONDITIONS = 'conditions';
const OP_MODES = 'operational-modes';
const ORBIT = 'orbit';
const POINTING_MODES = 'pointing-modes';
const TARGETS = 'targets';

const statsMenu: ISubMenu = {
  [SUB_ITEMS]: {
    [TARGETS]: {
      [TITLE]: 'Targets',
    },
    [CON_OPS]: {
      [TITLE]: 'ConOps',
    },
  },
};

const missionDesignerMenu: IMenu = {
  routeFn: Routes.MISSION_DESIGNER,
  dynamicItems: [],
  [ITEMS]: {
    [EDIT]: {
      [TITLE]: 'Edit',
      [SUB_ITEMS]: {
        [ORBIT]: {
          [TITLE]: 'Orbit & Time',
        },
        [TARGETS]: {
          [TITLE]: 'Targets',
        },
        [POINTING_MODES]: {
          [TITLE]: 'Pointing Modes',
        },
        [CONDITIONS]: {
          [TITLE]: 'Conditions',
        },
        [OP_MODES]: {
          [TITLE]: 'Operational Modes',
        },
      },
    },
    [ANALYZE]: {
      [TITLE]: 'Analyze',
      [SUB_ITEMS]: {
        [PLAYBACK]: {
          [TITLE]: 'Playback',
        },
        [TS]: {
          [TITLE]: 'Time Series',
          [SUB_ITEMS]: {
            [ORBIT]: {
              [TITLE]: 'Orbit',
            },
            [ATTITUDE]: {
              [TITLE]: 'Attitude',
            },
            [BODY_FRAME_VECTORS]: {
              [TITLE]: 'Body Frame Vectors',
            },
            [TARGETS]: {
              [TITLE]: 'Targets',
            },
            [CON_OPS]: {
              [TITLE]: 'ConOps',
            },
          },
        },
        [WS]: {
          [TITLE]: 'Window Statistics',
          ...statsMenu,
        },
        [FSS]: {
          [TITLE]: 'Full Simulation Statistics',
          ...statsMenu,
        },
      },
    },
  },
};

export const routePathsMDV = {
  BODY_FRAME_VECTORS,
  CON_OPS,
  CONDITIONS,
  OP_MODES,
  ORBIT,
  POINTING_MODES,
  TARGETS,
};

export default missionDesignerMenu;
