// Solar Panel Segment guidance
const guidance = {
  heading: 'Create and Edit Solar Panels',
  body: [
    {
      chunk:
        'Solar panels compose the solar arrays that power the satellite. Panels stored in this table can be swapped in and out of arrays to test different configurations within your mission architecture.',
    },
  ],
};

export default guidance;
