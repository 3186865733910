import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  highlightBar: {
    cursor: (props: { expanded?: boolean }) => {
      return props.expanded !== undefined ? 'pointer' : 'auto';
    },
    borderRadius: 4,
    '& rect': {
      opacity: 0,
      fill: theme.palette.action.hover,
      width: '100%',
      '&:hover': {
        opacity: 1,
      },
    },
    '&:active': {
      '& rect': {
        fill: (props: { expanded?: boolean }) => {
          return props.expanded !== undefined
            ? theme.palette.action.selected
            : theme.palette.action.hover;
        },
      },
    },
  },
  barExpandIcon: {
    color: 'rgba(221, 221, 221, 0.5)',
    '& path': {
      transform: (props: { expanded?: boolean }) => {
        return `rotate(${!props.expanded ? '0deg' : '180deg'}) translate(${
          !props.expanded ? '0px, 0px' : '-24px, -22px'
        })`;
      },
    },
  },
}));

export default useStyles;
