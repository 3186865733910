/* eslint no-template-curly-in-string: 0 */
import * as Yup from 'yup';

const validation = Yup.object().shape({
  configurationType: Yup.object().required('Select a configuration type.'),

  initialSoc: Yup.number()
    .required('Initial state of charge is required.')
    .max(100, 'Initial state of charge cannot be greater than ${max}%.')
    .moreThan(0, 'Initial state of charge must be more than ${more}%.')
    .test(
      'Test min when minSocOverride is provided',
      'Initial state of charge cannot be less than minimum state of charge.',
      function (initialSoc: number | undefined) {
        if (this.parent.minSocOverride && initialSoc) {
          return initialSoc >= this.parent.minSocOverride;
        }
        return true;
      }
    ),

  minSocOverride: Yup.number()
    .moreThan(0, 'Minimum state of charge must be more than ${more}%.')
    .max(100, 'Minimum state of charge cannot be greater than ${max}%.'),

  maxChargeCurrentOverride: Yup.number().moreThan(
    0,
    'Maximum charge current must be greater than ${more}.'
  ),

  maxDischargeCurrentOverride: Yup.number().moreThan(
    0,
    'Maximum discharge current must be greater than ${more}.'
  ),
});

export default validation;
