import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: theme.palette.background.contrastText,
    ...theme.typography.highlight1,
    ...theme.dimensions.inputs,
    lineHeight: theme.dimensions.inputs.height + 'px',
    padding: 0,
  }),
}));

export default useStyles;
