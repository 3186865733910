import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface IProps {
  content: string | JSX.Element;
  size?: number;
  style?: React.CSSProperties;
}

const InfoBadge = ({ content, size = 18, style }: IProps) => {
  return (
    <Tooltip title={content} placement="right" arrow>
      <InfoOutlinedIcon style={{ height: size, width: size, ...style }} />
    </Tooltip>
  );
};

export default InfoBadge;
