import { makeStyles } from '@material-ui/core/styles';
import { padding } from '../styles';

const subListItem = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: padding,
};
const subPadding = 25;

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0 + 'px ' + padding + 'px',
  },

  listItemText: {
    ...theme.typography.h3,
    color: theme.palette.background.contrastText,
  },

  subList: {
    paddingTop: 0,
    marginBottom: 5,
  },

  subListItem: {
    ...subListItem,
    paddingLeft: subPadding,
  },

  subSubListItem: {
    ...subListItem,
    paddingLeft: subPadding * 2,
  },

  subListItemText: {
    ...theme.typography.h5,
  },

  expandIcon: {
    fill: theme.palette.background.contrastText,
  },
  editSpacecraftText: {
    paddingLeft: padding,
  },
}));

export default useStyles;
