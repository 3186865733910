import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { IColumn } from 'components/general/types';
import guidance from './guidance';
import { IBusRegulator } from 'components/general/types/power';
import { useActiveEntities } from 'hooks';
import BusRegulatorDialog from './BusRegulatorDialog';

interface IProps {
  index: string;
}

const busRegulatorColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Rating',
    render: (row) => `${row.maxOutputPower} W`,
  },
  {
    title: 'Voltage',
    render: (row) => `${row.voltage} V`,
  },
];

const BusRegulatorsSegment = (props: IProps) => {
  const { index } = props;
  const { busRegulators } = useActiveEntities();

  return (
    <EntityTableSegment<IBusRegulator>
      title="Bus Regulators"
      index={index}
      guidance={guidance}
      entityColumns={busRegulatorColumns}
      entityData={busRegulators}
      DialogComponent={BusRegulatorDialog}
      modelName="bus regulator"
    ></EntityTableSegment>
  );
};

export default BusRegulatorsSegment;
