import { SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';

export const TARGETS = 'targets';
const INDEX = 'targets';
const TARGET_GROUPS = 'target-groups';

const aTTargetsMenu: IMenu['items'] = {
  [TARGETS]: {
    [TITLE]: 'Targets',
    [SUB_ITEMS]: {
      [INDEX]: {
        [TITLE]: 'Targets',
      },
      [TARGET_GROUPS]: {
        [TITLE]: 'Target Groups',
      },
    },
  },
};
export const wGroupIndicesATTargets = {
  TARGETS: `${TARGETS}*`,
  TARGETS_INDEX: `${TARGETS}.${INDEX}`,
  TARGETS_TARGET_GROUPS: `${TARGETS}.${TARGET_GROUPS}`,
};

export default aTTargetsMenu;
