import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { IColumn } from 'components/general/types';
import guidance from './guidance';
import { ISolarCell } from 'components/general/types/power';
import { useActiveEntities } from 'hooks';
import Dialog from './Dialog';

interface IProps {
  index: string;
}

const solarCellsColumns: IColumn[] = [
  { title: 'Part Number', field: 'partNumber' },
  {
    title: 'Manufacturer',
    field: 'manufacturer',
  },
];

const SolarCellsSegment = (props: IProps) => {
  const { index } = props;
  const { solarCells } = useActiveEntities();

  return (
    <EntityTableSegment<ISolarCell>
      title="Solar Cells"
      index={index}
      guidance={guidance}
      entityColumns={solarCellsColumns}
      entityData={solarCells}
      DialogComponent={Dialog}
      modelName="solar cell"
    ></EntityTableSegment>
  );
};

export default SolarCellsSegment;
