import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { IColumn } from 'components/general/types';
import guidance from './guidance';
import { IBatteryPack, ISubsystem } from 'components/general/types/power';
import { useActiveEntities } from 'hooks';
import COTSDialogButton from 'components/general/COTSDialogButton';
import Dialog from './Dialog';

interface IProps {
  index: string;
}

const batteryPackColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  { title: 'Cells In Parallel', field: 'numParallel' },
  { title: 'Cells In Series', field: 'numSeries' },
];

const BatteryPacksSegment = (props: IProps) => {
  const { index } = props;
  const { batteryPacks, subsystems } = useActiveEntities();
  const powerSubsystem = subsystems.find((s) => s.category === 'POWER');

  return (
    <EntityTableSegment<IBatteryPack>
      index={index}
      guidance={guidance}
      entityColumns={batteryPackColumns}
      entityData={batteryPacks}
      title="Battery Packs"
      titleButton={
        <COTSDialogButton entityKey="batteryPacks" subsystem={powerSubsystem as ISubsystem} />
      }
      widgetTitle="Pack Instances"
      modelName="battery pack"
      DialogComponent={Dialog}
    ></EntityTableSegment>
  );
};

export default BatteryPacksSegment;
