import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  solarViewFactor: number;
}

const useStyles = makeStyles({
  solarPanel: (props: IProps) => ({
    width: '100%',
    textAlign: 'center',
    padding: '.5em 1em',
    boxSizing: 'border-box',
    color: props.solarViewFactor > 0.5 ? 'black' : 'white',
    fontSize: '.8rem',
    backgroundColor:
      props.solarViewFactor > 0 ? `rgba(238, 216, 72, ${props.solarViewFactor})` : 'inherit',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
});

export default useStyles;
