import useStyles from './styles';

export interface IProps {
  soc: number;
}

const BatteryCharge = (props: IProps) => {
  const { soc } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.batteryContainer}>
      <div className={classes.batteryTopContainer}>
        <div className={classes.batteryTop}></div>
      </div>
      <div className={classes.batteryShell}>
        <div className={classes.batteryBarContainer}>
          <div className={classes.batteryBars}></div>
          <div className={classes.batteryBars}></div>
          <div className={classes.batteryBars}></div>
          <div className={classes.batteryBars}></div>
          <div className={classes.batteryBars}></div>
          <div className={classes.batteryBars}></div>
          <div className={classes.batteryBars}></div>
          <div className={classes.batteryBars}></div>
          <div className={classes.batteryBars}></div>
          <div className={classes.batteryBars}></div>
          <div className={classes.batterySocContainer}>
            <p className={classes.batterySoc}>{`${Math.floor(soc * 100)}%`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatteryCharge;
