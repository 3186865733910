import { makeStyles } from '@material-ui/core/styles';

export const labelMarginTop = 5;

const useStyles = makeStyles(
  (theme) => ({
    root: (props) => ({
      color: props.readOnly ? theme.palette.action.disabled : theme.palette.background.contrastText,
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      ...theme.typography.highlight1,
      width: '100%',
      border: '1px solid ' + theme.palette.background.contrastText,
      ...theme.dimensions.inputs,
      height: props.multiline ? 'inherit' : theme.dimensions.inputs.height,
      padding: 0,
      display: 'flex',

      '&.Mui-disabled': {
        color: theme.palette.action.disabled,
      },

      '& input, textarea': {
        padding: '6px 12px',
      },

      '& button.MuiIconButton-edgeEnd': {
        marginRight: 0,

        '& svg': {
          fill: theme.palette.background.contrastText,
        },
      },

      '& .MuiInputAdornment-positionEnd': {
        marginRight: labelMarginTop,

        '& button': {
          marginRight: -5, // Cancel out global margin if button
        },
      },
    }),
  }),
  { index: 1 }
);

export default useStyles;
