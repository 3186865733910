import { makeGuidance } from 'hooks';

const loadStateHeading = 'Load States';

const loadStatePermanentGuidance = [
  {
    chunk:
      "Load states are used to define the different loading configurations of a component. A load state is active when one of its associated operational modes is the active mode.  At each step, the loads under each component's active load state are powered.",
  },
  {
    chunk:
      "For example, a radio could have two load states: 'Rx' and 'Tx/Rx'.  To capture the loading of receive mode (Rx), a load state could be created with loading on the 5V bus.  This load state could then be associated to operational modes where only the receiver should be powered.  In the transmit and receive mode (Tx/Rx), another load state could be created with loading on both the 5V and 12V buses.  Like the Rx case, this load state could be associated to the operational modes where the transmitter and receiver should both be powered.",
  },
  {
    subHeading: 'Create and Edit Loads',
    chunk: 'Create and edit the loads that will be powered when this load state is active.',
  },
  {
    subHeading: 'Link to Operational Modes',
    chunk: 'Select the operational modes for which this load state will be active.',
  },
];

export const useGuidance = makeGuidance({
  _default: {
    heading: loadStateHeading,
    body: loadStatePermanentGuidance,
  },

  efficiency: {
    heading: loadStateHeading,
    body: [
      ...loadStatePermanentGuidance,
      {
        subHeading: 'Load State Efficiency',
        chunk:
          "Each load state can be assigned an efficiency.  When active, a load state's efficiency is used to determine how much of a component's power consumption is dissipated as heat vs. converted to a different form of energy (mechanical, electromagnetic, etc.). An efficiency value of 0% implies that 100% of the load power is dissipated as heat.",
      },
    ],
  },
});
