import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  summary: {
    marginBottom: 30,
    '& tbody td': {
      width: 120,
    },
  },
  subHeading: {
    fontWeight: 500,
  },
  modePlaybackWidget: {
    maxWidth: 600,
    [theme.breakpoints.up('xl')]: {
      maxWidth: '100%',
    },
  },
}));

export default useStyles;
