import { makeGuidance } from 'hooks';

const busRegulatorPermanentGuidance =
  'Bus Regulators regulate their output to a fixed voltage for the components and bus regulators that they provide power to.';

const inputSourceGuidance = {
  heading: 'Bus Regulators',
  body: [
    {
      chunk: busRegulatorPermanentGuidance,
    },
    {
      subHeading: 'Input Source',
      chunk:
        'Input power to this bus regulator can be supplied directly from the power controller bus or from another bus regulator.',
    },
  ],
};

export const useGuidance = makeGuidance({
  _default: {
    heading: 'Bus Regulators',
    body: [
      {
        chunk: busRegulatorPermanentGuidance,
      },
    ],
  },

  inputType: inputSourceGuidance,

  inRegulator: inputSourceGuidance,

  voltage: {
    heading: 'Bus Regulators',
    body: [
      {
        chunk: busRegulatorPermanentGuidance,
      },
      {
        subHeading: 'Operating Voltage',
        chunk: 'The regulated output voltage of the bus regulator.',
      },
    ],
  },

  efficiency: {
    heading: 'Bus Regulators',
    body: [
      {
        chunk: busRegulatorPermanentGuidance,
      },
      {
        subHeading: 'Conversion Efficiency',
        chunk: 'The power conversion efficiency of the bus regulator.',
      },
    ],
  },

  maxOutputPower: {
    heading: 'Bus Regulators',
    body: [
      {
        chunk: busRegulatorPermanentGuidance,
      },
      {
        subHeading: 'Maximum Output Power',
        chunk:
          'The maximum power that the bus regulator can output. Exceeding this limit will cause the simulation to fail.',
      },
    ],
  },
});

const busRegulatorsSegmentGuidance = {
  heading: 'Bus Regulators',
  body: [
    {
      chunk: busRegulatorPermanentGuidance,
    },
  ],
};

export default busRegulatorsSegmentGuidance;
