import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 20,
  },
  buttonWrapper: {
    marginTop: 20,
    marginBottom: 10,
    width: '100%',
  },
  footer: {
    fontSize: '1.01em',
  },
}));

export default useStyles;
