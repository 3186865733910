import Accent from 'components/general/Accent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass } from '@fortawesome/free-solid-svg-icons';

const GncAccent = (props) => {
  const { children, ...remainingProps } = props;

  return (
    <Accent icon={<FontAwesomeIcon icon={faCompass} />} {...remainingProps}>
      {children}
    </Accent>
  );
};

export default GncAccent;
