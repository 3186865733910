import useStyles from './styles';

export default function ViewPort(props) {
  const { children } = props;
  const classes = useStyles(props);

  return (
    // id="viewport" is used by the scroll-related events
    <div id="viewport" className={classes.root}>
      <div className={classes.content}>{children}</div>
    </div>
  );
}
