import { useCallback, useState } from 'react';
import Widget from 'components/general/widgets/Widget';
import { logo } from 'multimedia/brand';
import useStyles from './styles';
import StyledLabel from 'components/general/StyledLabel';
import A from 'components/general/A';
import {
  LICENSE_AGREEMENT_URL,
  TERMS_OF_USE_URL,
  PRIVACY_POLICY_URL,
  RECAPTCHA_PRIVACY_URL,
  RECAPTCHA_TERMS_URL,
} from 'config';
import SupportDialog from 'components/general/dialogs/SupportDialog';

const AuthWidget = (props) => {
  const { children } = props;

  const [supportDialogConfig, setSupportDialogConfig] = useState({ open: false });

  const classes = useStyles();

  const openSupportDialog = useCallback(
    (e) => {
      e.preventDefault();
      setSupportDialogConfig({ open: true });
    },
    [setSupportDialogConfig]
  );

  return (
    <>
      <div className={classes.root}>
        <Widget className={classes.widget} minWidth={320}>
          <div className={classes.brandWrapper}>
            <img src={logo} alt="logo" className={classes.brand} />
          </div>
          {children}
          <div className={classes.legal}>
            <StyledLabel monotone>
              <A href={LICENSE_AGREEMENT_URL} target="_blank">
                Licensing
              </A>
              {' | '}
              <A href={TERMS_OF_USE_URL} target="_blank">
                Terms
              </A>
              {' | '}
              <A href={PRIVACY_POLICY_URL} target="_blank">
                Privacy
              </A>
              {' | '}
              <A href="#" onClick={openSupportDialog}>
                Support
              </A>
            </StyledLabel>
            <StyledLabel monotone className={classes.recaptchaLegal}>
              {'Protected by reCAPTCHA | '}
              <A href={RECAPTCHA_PRIVACY_URL} target="_blank">
                Privacy
              </A>
              {' | '}
              <A href={RECAPTCHA_TERMS_URL} target="_blank">
                Terms
              </A>
            </StyledLabel>
          </div>
        </Widget>
      </div>
      <SupportDialog
        config={supportDialogConfig}
        onClose={() =>
          setSupportDialogConfig({
            ...supportDialogConfig,
            open: false,
          })
        }
      />
    </>
  );
};

export default AuthWidget;
