import { useContext } from 'react';
import { ContextNavContext } from 'providers';
import Masonry from './Masonry';

export default function WGroup(props) {
  let { children, exclude, index, masonryConfig, maxWidth } = props;

  const activeKey = useContext(ContextNavContext)?.state?.activeKey;

  // masonryConfig -> {minWidthBreakpoint: [column widths %s]}
  // ex: {0: [100], 1600: [50, 50]} -> at 0px and above, render one column with 100% width, 1600px and above render 2 columns at 50% width
  // Masonry can also accept MUI breakpoints (xs, sm, lg, xl, etc) which are defined in theme.js
  // ex: {xs: [100], lg: [50, 50]}

  const wildcardIndex = index?.indexOf('*');
  const wildcardExcludeIndex = exclude?.indexOf('*');

  if (
    exclude &&
    ((wildcardExcludeIndex !== -1 &&
      activeKey?.indexOf(exclude.substring(0, wildcardExcludeIndex)) === 0) ||
      activeKey === exclude)
  ) {
    return null;
  } else if (
    activeKey === index ||
    (Array.isArray(index) && index.includes(activeKey)) ||
    (activeKey &&
      wildcardIndex !== -1 &&
      activeKey.indexOf(index.substring(0, wildcardIndex)) === 0) ||
    (!activeKey && !exclude && !index) // allows using WGroup as a simple wrapper component if you don't pass these props
  ) {
    return (
      <Masonry maxWidth={maxWidth} masonryConfig={masonryConfig}>
        {children}
      </Masonry>
    );
  } else {
    return null;
  }
}
