import {
  useState,
  useMemo,
  createContext,
  useContext,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';
import { dateFormatLong, mjd2Moment } from 'utils/time';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useDispatch } from 'react-redux';
import useUnmountEffect from 'hooks/useUnmountEffect';
import { DataContext } from './DataProvider';
import { ActiveBranchContext } from './ActiveBranchProvider';

interface IProps {
  children?: ReactNode;
}

interface ITimeContext {
  time: number;
  setTime: (time: number, first?: boolean) => void;
  dt: number;
  index: number;
  timeFormatted: string;
}

interface IState {
  time: number;
  updatedAt: number | null;
  dt: number;
  prevTime: number | null;
  prevUpdatedAt: number | null;
}

const defaultState: IState | number = {
  time: 0,
  updatedAt: null,
  dt: 0,
  prevTime: null,
  prevUpdatedAt: null,
};

const defaultValue: ITimeContext = {
  time: defaultState.time,
  setTime: () => undefined,
  dt: defaultState.dt,
  index: 0,
  timeFormatted: '',
};

/**
 * Provides the time value for the current playback state. Subscribing to this
 * provider makes a component rerender at _every tick!_
 * - Also includes a `setTime` function to update the time.
 *
 * Subscribing to this context implicitly subscribes a component to:
 * - ActiveBranchContext: provides the active branch to cache playback time
 * - DataContext: provides the current scenario's start time
 */
export const TimeContext = createContext(defaultValue);

const TimeProvider = (props: IProps) => {
  const { children } = props;
  const { branch } = useContext(ActiveBranchContext);
  const { startTime } = useContext(DataContext);
  const dispatch = useDispatch();
  const {
    MissionVersion: {
      actions: { updateAnalyzeState },
    },
  } = SatelliteApi;

  const [state, setState] = useState(defaultState);

  const setTime = useCallback(
    (time, first = false) => {
      setState((curr) => {
        if (first) {
          return {
            ...defaultState,
            time: branch.analyzeState?.playbackTime || time,
          };
        }
        const updatedAt = new Date().getTime() / 1000;
        return {
          ...curr,
          time,
          updatedAt,
          prevTime: curr.time,
          prevUpdatedAt: curr.updatedAt,
          dt: curr.updatedAt ? (time - curr.time) / (updatedAt - curr.updatedAt) : 0, // MJDs per wall second
        };
      });
    },
    [setState, branch.analyzeState]
  );

  // Initialize start time
  useEffect(() => {
    if (startTime) setTime(startTime, true);
  }, [startTime, setTime]);

  const value = useMemo(() => {
    const time = state.time;
    return {
      time: Math.max(startTime, time),
      dt: state.dt,
      // TODO: remove `index`! NOTE: don't use `index`, use time instead!
      index: 0,
      // `index` is only here b/c several legacy components are importing IndexContext and grabbing the `index`.
      setTime,
      timeFormatted: mjd2Moment(time).format(dateFormatLong),
    };
  }, [state, setTime, startTime]);

  // on unmount, store time in redux
  useUnmountEffect(() => {
    dispatch(updateAnalyzeState({ id: branch.id, playbackTime: value.time }));
  }, [dispatch, updateAnalyzeState, branch, value.time]);

  return <TimeContext.Provider value={value}>{children}</TimeContext.Provider>;
};

export default TimeProvider;
