import { makeStyles } from '@material-ui/core/styles';

const lazyPlaceholderHeight = 450;
const lazyPlaceholderFontSize = 120;

const useStyles = makeStyles((theme) => ({
  zoomControls: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: 0,
    marginBottom: 0,
    width: '100%',
    overflow: 'hidden',
    transition: 'height 500ms ease-in-out, margin-bottom 500ms ease-in-out',
  },
  slider: {
    margin: '0 20px',
  },
  wrapper: {
    minHeight: lazyPlaceholderHeight,
  },
  lazyPlaceholder: {
    textAlign: 'center',
    margin: '0 auto',
    '& svg': {
      marginTop: (lazyPlaceholderHeight - lazyPlaceholderFontSize) / 2,
      fontSize: lazyPlaceholderFontSize,
      color: theme.palette.background.inlayText,
    },
  },
}));

export default useStyles;
