import { SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';
import { routePathsCommon } from 'routes';
const { PLAYBACK } = routePathsCommon;
const CUSTOM = 'custom';

const agentCustomMenu: IMenu['items'] = {
  [CUSTOM]: {
    [TITLE]: 'Custom',
    [SUB_ITEMS]: {
      [PLAYBACK]: {
        [TITLE]: 'Playback',
      },
    },
  },
};
export const wGroupIndicesAgentCustom = {
  CUSTOM: `${CUSTOM}*`,
  PLAYBACK: `${CUSTOM}.${PLAYBACK}`,
};

export default agentCustomMenu;
