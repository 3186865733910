import { makeStyles } from '@material-ui/core/styles';
import { ModuleVables } from 'utils/vable';

const { SimulationStatuses } = ModuleVables;

const useStyles = makeStyles((theme) => ({
  progress: (props) => {
    return {
      '&.MuiCircularProgress-root': {
        color: props.loading
          ? theme.palette.action.disabledBackground
          : props.value === 100
          ? theme.palette.action.disabledBackground
          : props.value < 0
          ? theme.palette.action.disabledBackground
          : props.value === 0
          ? theme.palette.action.disabledBackground
          : theme.palette.primary.main,
      },
    };
  },
  value: (props) => {
    return {
      color:
        props.value === 100
          ? theme.palette.success.main
          : props.value < 0
          ? theme.palette.error.main
          : props.value === 0
          ? theme.palette.background.contrastText
          : theme.palette.background.contrastText,
      fontSize: '0.75em',

      '& svg': {
        height: 20,
        width: 'auto',
        marginTop: 6,
      },
    };
  },
  customTooltip: (props) => {
    return {
      backgroundColor:
        (props.status === SimulationStatuses.FAILED.value ||
          props.status === SimulationStatuses.MD_FAILURE.value ||
          props.status === SimulationStatuses.PARTIAL_RESULTS.value ||
          props.status === SimulationStatuses.ERROR.value) &&
        theme.palette.error.main,
      ...theme.typography.body,
    };
  },
  customArrow: (props) => {
    return {
      color:
        (props.status === SimulationStatuses.FAILED.value ||
          props.status === SimulationStatuses.MD_FAILURE.value ||
          props.status === SimulationStatuses.PARTIAL_RESULTS.value ||
          props.status === SimulationStatuses.ERROR.value) &&
        theme.palette.error.main,
    };
  },
}));

export default useStyles;
