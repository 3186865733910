import StyledCheckbox from '../StyledCheckbox.jsx';
import StyledLabel from 'components/general/StyledLabel.jsx';
import useStyles from './styles';

const LabeledCheckbox = (props) => {
  const {
    label,
    value,
    name,
    guide,
    onChange,
    formikOnChange,
    setFieldValue,
    disabled,
    ...remainingProps
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <StyledCheckbox
        name={name}
        onChange={(val) => {
          // allow for custom on changes
          if (formikOnChange) {
            formikOnChange(val);
          }
          // perform switch of checked state regardless of it there is a custom on change
          setFieldValue(name, !value);
        }}
        // setState is asynchronous so when switching data types and what inputs are rendered occassionally non boolean values get passed through
        // this ternary operator checks and sets checkbox value to false to avoid any warnings in console (see scalar in condition form for example)
        checked={typeof value === 'boolean' ? value : value === 1}
        disabled={disabled}
        {...remainingProps}
      />
      <StyledLabel disabled={disabled}>{label}</StyledLabel>
    </div>
  );
};

export default LabeledCheckbox;
