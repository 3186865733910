import WGroup from 'components/general/WGroup';
import Wizard from 'components/general/wizards/Wizard';
import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import PowerProcessingEditBoard from './PowerProcessingEditBoard';
import PowerGenerationEditBoard from './PowerGenerationEditBoard';
import EnergyStorageEditBoard from './EnergyStorageEditBoard';
import SubsystemLoadingEditBoard from './SubsystemLoadingEditBoard';

const PowerEditBoard = () => {
  return (
    <WGroup index={wGroupIndicesATPower.POWER}>
      <Wizard>
        <PowerProcessingEditBoard />
        <PowerGenerationEditBoard />
        <EnergyStorageEditBoard />
        <SubsystemLoadingEditBoard />
      </Wizard>
    </WGroup>
  );
};

export default PowerEditBoard;
