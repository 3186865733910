import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 20,
  },
  footer: {
    fontSize: '1em',
  },
}));

export default useStyles;
