import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string().required('A thermal interface name is required.'),
  useInterfaceMaterial: Yup.boolean(),
  material: Yup.object().when('useInterfaceMaterial', {
    is: true,
    then: Yup.object().required('Select a thermal interface material.'),
  }),
  area: Yup.number().when('useInterfaceMaterial', {
    is: true,
    then: Yup.number().required('An area is required.').moreThan(0, 'Area must be positive.'),
  }),
});

export default validation;
