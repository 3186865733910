// Disabling this lint rule because of Yup's use of string curly brackets for its built-in variables
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

const minCurvePoints = 2;
const minCurvePointsMessage = 'There must be at least ${min} points on the curve.';

const validation = Yup.object().shape({
  manufacturer: Yup.string().max(32, 'Manufacturer must be no more than ${max} characters.'),

  partNumber: Yup.string()
    .required('A part number is required.')
    .max(32, 'Part Number must be no more than ${max} characters.'),

  capacity: Yup.number()
    .required('A capacity is required.')
    .min(0, 'Capacity must be at least ${min}'),

  esr: Yup.number()
    .required('Equivalent series resistance is required.')
    .moreThan(0, 'Equivalent series resistance must be greater than ${more}.'),

  maxChargeCurrent: Yup.number()
    .required('A maximum charge current is required.')
    .moreThan(0, 'Maximum charge current must be greater than ${more}.'),

  maxDischargeCurrent: Yup.number()
    .required('A maximum discharge current is required.')
    .moreThan(0, 'Maximum discharge current must be greater than ${more}.'),

  minSoc: Yup.number()
    .required('A minimum state of charge is required.')
    .min(0, 'Minimum state of charge must be at least ${min}%.')
    .max(100, 'Minimum state of charge must be no greater than ${max}%.'),

  curve: Yup.object().shape({
    pointsSoc: Yup.array(
      Yup.number()
        .required('A state of charge is required for each point on the curve.')
        .min(0, 'Points on the curve must have a state of charge of at least ${min}%.')
        .max(100, 'Points on the curve must have a state of charge no greater than ${max}%.')
    ).min(minCurvePoints, minCurvePointsMessage),
    pointsVoc: Yup.array(
      Yup.number()
        .required('An open circuit voltage is required for each point on the curve.')
        .moreThan(0, 'Points on the curve must have an open circuit voltage greater than ${more}.')
    ).min(minCurvePoints, minCurvePointsMessage),
  }),
});

export default validation;
