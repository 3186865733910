import { ActiveBranchContext } from 'providers';
import { useContext } from 'react';

// Returns object of all active entities
// Automatically subscribes component to ActiveBranchContext
//   - And thus requires the calling component to be in ActiveBranchProvider!
// Mainly exists for backwards compatibility – this hook used to compile `activeEntities` itself
const useActiveEntities = () => {
  const { activeEntities } = useContext(ActiveBranchContext);
  return activeEntities;
};

export default useActiveEntities;
