import clsx from 'clsx';
import ViewPortInlay from '../ViewPortInlay';
import useStyles from './styles';
import SpacecraftDialogInlayButton from './SpacecraftDialogInlayButton';

interface IProps {
  dialogTabNumber: number;
  // text must contain "spacecraftDialog", which will be replaced with the inlayButton
  text: string;
  className?: string;
}

const SpacecraftDialogInlay = (props: IProps) => {
  const { dialogTabNumber, text, className } = props;
  const [preButton, postButton] = text.split('spacecraftDialog');
  const classes = useStyles();
  return (
    <ViewPortInlay
      className={clsx(className, classes.spacecraftDialogInlay)}
      text={
        <>
          {preButton} <SpacecraftDialogInlayButton dialogTabNumber={dialogTabNumber} />
          {postButton}
        </>
      }
    />
  );
};

export default SpacecraftDialogInlay;
