import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { useInReadOnlyBranch } from 'hooks';

const StyledCheckboxTemp = styled(Checkbox)`
  &.MuiButtonBase-root {
    padding: 0;
    margin-right: 10px;
    span {
      color: ${(props) =>
        props.checked ? props.theme.palette.text.primary : props.theme.palette.text.tertiary};
      &:hover {
        background-color: transparent;
      }
    }
  }
`;

const StyledCheckbox = (props) => {
  const inReadOnlyBranch = useInReadOnlyBranch();
  return <StyledCheckboxTemp disabled={props.disabled || inReadOnlyBranch} {...props} />;
};

export default StyledCheckbox;
