import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',

    backgroundColor: theme.palette.background.dark,
  },
  content: {
    position: 'relative',
    top: '50%',
    width: '50%',
    margin: 'auto',

    '-webkit-transform': 'translateY(-50%)',
    '-moz-transform': 'translateY(-50%)',
    '-o-transform': 'translateY(-50%)',
    '-ms-transform': 'translateY(-50%)',

    textAlign: 'center',

    paddingBottom: 10,
  },
  contentInlay: {
    textAlign: 'center',
    padding: '20px 0px',
  },
  spinnerWrapper: {
    marginBottom: 20,
  },
  message: {
    color: theme.palette.background.contrastText,
  },
  brand: {
    height: 90,
    width: 'auto',
  },
}));

export default useStyles;
