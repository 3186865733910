import { makeGuidance } from 'hooks';

const thermalInterfacesPermanentGuidance =
  'Thermal Interfaces are what allows heat to flow between components during the simulation. Lack of an interface connection to a Component indicates to the simulation that a Component is completely isolated. Thermal radiation is not currently modeled between Components.';

export const useGuidance = makeGuidance({
  _default: {
    heading: 'Thermal Interfaces',
    body: [
      {
        chunk:
          'Set the properties of the thermal interface. More area means less resistance as there is more surface for heat to flow through.',
      },
      {
        chunk:
          'A bonded interface implies perfect contact between the interfaced materials. Energy can flow between them without resistance.',
      },
      {
        chunk:
          'A non-bonded interface describes indirect contact between the interfaced materials. Facilitating the connection is a third material that provides some resistance to heat flow. A larger contact area means less thermal resistance.',
      },
    ],
  },
});

const thermalInterfacesSegmentGuidance = {
  heading: 'Thermal Interfaces',
  body: [
    {
      chunk: thermalInterfacesPermanentGuidance,
    },
    {
      chunk:
      'To create an interface, click on a Component and drag the line to the Component you with to connect it to. Components not currently connected are stored to the upper right of the Thermal Interface chart. Drag with the mouse to reveal more canvas.',
    },
    {
      chunk:
      'Surfaces are unique. They are non-physical entities representing aspects of other components. Each Surface must be attached to one (and only one) component. The interface between the surface and the component will always have zero thermal resistance to represent the fact that the surface is part of that component.',
    },
    {
      chunk:
        'For Thermal Interfaces used between Temperature Controllers and a sink Component and a regulated Component, remember: The regulated Component does not need to be the same as the Temperature Controller`s controlled Component.',
    },
  ],
};

export default thermalInterfacesSegmentGuidance;
