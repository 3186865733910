import { useRef, ReactNode } from 'react';
import { Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { checkIsTruncated } from '../utils';
interface IProps {
  title: string;
  subtitle?: string;
  truncateTitleOverflow?: boolean;
  truncateSubtitleOverflow?: boolean;
  containerStyle?: { [key: string]: string | number };
  children: ReactNode;
}

const BaseNode = ({
  title,
  subtitle = '',
  containerStyle = {},
  truncateTitleOverflow = true,
  truncateSubtitleOverflow = true,
  children,
}: IProps) => {
  const styles = useStyles({ truncateSubtitleOverflow, truncateTitleOverflow });

  const titleRef = useRef<HTMLParagraphElement>(null);
  const titleTruncated = checkIsTruncated(titleRef.current);

  const subtitleRef = useRef<HTMLParagraphElement>(null);
  const subTitleTruncated = checkIsTruncated(subtitleRef.current);

  return (
    <div className={styles.root} style={containerStyle}>
      <Tooltip title={titleTruncated ? title : ''} placement="right" arrow>
        <p className={styles.title} ref={titleRef}>
          {title}
        </p>
      </Tooltip>
      {subtitle && (
        <Tooltip title={subTitleTruncated ? subtitle : ''} placement="right" arrow>
          <p className={styles.subtitle} ref={subtitleRef}>
            {subtitle}
          </p>
        </Tooltip>
      )}
      {children}
    </div>
  );
};

export default BaseNode;
