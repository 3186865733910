import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  resolutionRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    marginTop: 20,
  },
  title: {
    width: '50%',
    textAlign: 'center',
  },
}));

export default useStyles;
