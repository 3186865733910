import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: props.height ? props.height : 'auto',
  }),
}));

const WaveDecoration = (props) => {
  const { className, height } = props;
  const classes = useStyles({ height });

  return (
    <Fragment>
      <svg
        className={clsx(classes.root, className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 240.001 47.744"
        preserveAspectRatio="none"
      >
        <path
          id="Path_113"
          data-name="Path 113"
          d="M8993.392-2927.808c3.615-.5,33.595-13.99,131.278,7.484s108.723,20.242,108.723,20.242v15.379h-240Z"
          transform="translate(-8993.392 2932.448)"
          fill={theme.palette.background.main + '7D'}
        />
      </svg>
      <svg
        className={clsx(classes.root, className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 240 40.035"
        preserveAspectRatio="none"
      >
        <path
          id="Path_114"
          data-name="Path 114"
          d="M8838.487-2910.837c54.769-7.891,40.934-18.6,107.315-17.866s132.685,11.8,132.685,11.8v28.2h-240Z"
          transform="translate(-8838.487 2928.739)"
          fill={theme.palette.background.main + '91'}
        />
      </svg>
      <svg
        className={clsx(classes.root, className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 240.001 44.791"
        preserveAspectRatio="none"
      >
        <path
          id="Path_115"
          data-name="Path 115"
          d="M8590-2695.057s-.631-1.111,25.049,5.387,41.555,6.7,120.082,6.848,94.869-24.8,94.869-24.8v44.791H8590Z"
          transform="translate(-8589.999 2707.621)"
          fill={theme.palette.background.main}
        />
      </svg>
      <svg
        className={clsx(classes.root, className)}
        style={{ bottom: height ? (height * 16) / 46 : 16 }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 240.251 46.16"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.436"
            y1="1"
            x2="0.494"
            y2="-0.369"
            gradientUnits="objectBoundingBox"
            gradientTransform="rotate(30)"
          >
            <stop offset="0" stopColor={theme.palette.secondary.light} stopOpacity="0.3" />
            <stop offset="1" stopColor={theme.palette.secondary.light} stopOpacity="0" />
          </linearGradient>
        </defs>
        <path
          id="Path_116"
          data-name="Path 116"
          d="M8590.709-2696.078s42.569-20.114,96.415-16.784,44.777,16.106,87.093,16.231c16.757.05,29.141-1.636,38.272-3.756,13.207-3.066,18.471-7.056,18.471-7.056v-32.527L8591-2742.238Z"
          transform="translate(-8590.709 2742.238)"
          fill="url(#linear-gradient)"
        />
      </svg>
    </Fragment>
  );
};

export default WaveDecoration;
