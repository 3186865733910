// Solar Cell Segment guidance
const guidance = {
  heading: 'Create and Edit Solar Cells',
  body: [
    {
      chunk:
        'Solar cells compose the solar panels that power the satellite. Cells in this table can be swapped in and out to test different cell technologies within your mission architecture.',
    },
  ],
};

export default guidance;
