import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hr: {
    width: '100%',
    margin: '15px 0',
    marginTop: 0,
  },
  tabsWrapper: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    '& p': {
      textAlign: 'left',
      maxWidth: '7.8rem',
      overflow: 'hidden',
      whiteSpace: 'break-spaces',
      textOverflow: 'ellipsis',
    },
    borderRight: `1px solid rgba(255, 255, 255, 0.12)`,
    '& button.MuiTab-root': {
      padding: 5,

      '& span.MuiTab-wrapper': {
        marginLeft: 0,
        marginRight: 'auto',
        width: 'auto',
      },
    },
  },
  panel: {
    width: '100%',
    paddingLeft: 20,
    paddingTop: 10,
  },
}));

export default useStyles;
