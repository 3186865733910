import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    display: 'flex',
  },
  panelHeader: {
    alignContent: 'flex-start',
    marginRight: 20,
    fontSize: '0.90rem',
    marginBottom: 10,
  },

  footer: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: 20,

    '& button': {
      marginLeft: '10px !important',
    },
  },
}));

export default useStyles;
