import { OptionsObject, SnackbarMessage, useSnackbar as useSnackbarBase } from 'notistack';
import { useCallback } from 'react';

const useSnackbar = () => {
  const { enqueueSnackbar: enqueueSnackbarBase, closeSnackbar } = useSnackbarBase();

  const enqueueSnackbar = useCallback(
    (message: SnackbarMessage, options?: OptionsObject) => {
      if (!options) {
        // Default if options input is not specified
        options = { variant: 'error' };
      } else if (!options.variant) {
        // If no variant is specified
        options.variant = 'error';
      }

      if (options.variant === 'error') {
        // If variant is 'error'
        if (!options.persist) {
          // If persist is not specified
          options.persist = true;
        }
      } else if (options.variant === 'success') {
        // If variant is 'success'
        if (!options.persist) {
          // If persist is not specified
          options.persist = false;
        }
      }

      enqueueSnackbarBase(message, options);
    },
    [enqueueSnackbarBase]
  );

  return {
    enqueueSnackbar,
    closeSnackbar,
  };
};

export default useSnackbar;
