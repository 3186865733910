import { makeStyles } from '@material-ui/core/styles';

export const iconDims = 35;

const useStyles = makeStyles((theme) => ({
  supportChannel: {
    textAlign: 'center',
    margin: '20px 0',

    '& svg': {
      width: iconDims,
      height: iconDims,
    },

    '& a': {
      display: 'block',
      fontSize: 17,
    },
  },
}));

export default useStyles;
