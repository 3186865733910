import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

const minSizeStyles = {
  minWidth: 0,
  padding: '0px 10px',
  height: 'auto',
};

const defaultSizeStyles = {
  height: theme.dimensions.inputs.height,
  minWidth: 100,
};

const textColor = (props) => {
  if (props.framed) {
    return props.disabled
      ? theme.palette.action.disabled
      : !props.error
      ? theme.palette.primary.main
      : theme.palette.error.light;
  } else {
    return theme.palette.primary.contrastText;
  }
};

const useStyles = makeStyles((theme) => ({
  wrapper: (props) => ({
    cursor: props.disabled ? 'not-allowed' : 'auto',
    ...(props.fullWidth && { width: '100%' }),
  }),

  button: (props) => ({
    '&.MuiButtonBase-root': {
      margin: '5px 0',
      minWidth: 100,
      textTransform: 'none',
      borderRadius: theme.dimensions.inputs.borderRadius,
      backgroundColor: props.backgroundColor
        ? props.backgroundColor
        : props.framed
        ? '#00000000'
        : props.disabled || props.off
        ? theme.palette.action.disabled
        : props.error && !props.framed
        ? theme.palette.error.light
        : theme.palette.primary.main,
      border:
        props.framed && !props.borderless
          ? props.disabled || props.off
            ? '2px solid ' + theme.palette.action.disabled
            : '1px solid ' + (!props.error ? theme.palette.primary.main : theme.palette.error.light)
          : 'none',
      ...(props.min ? minSizeStyles : defaultSizeStyles),

      marginTop: () => {
        if (props.tallMargin) {
          return '20px';
        } else if (props.midMargin) {
          return '10px';
        }
      },

      '&:hover': {
        backgroundColor:
          props.error && !props.framed
            ? theme.palette.error.main
            : !props.framed
            ? theme.palette.primary.dark
            : // default MUI button hover color
              'rgba(221, 221, 221, 0.08)',
      },
    },
  }),

  label: {
    ...theme.typography.highlight1,
    color: textColor,
    display: 'flex',
    alignItems: 'center',
  },

  contentContainer: (props) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),

  spinner: {
    color: textColor,
  },

  spacer: {
    display: 'flex',
    justifyContent: (props) => {
      if (props.replaceSpinner) {
        return 'center';
      } else {
        return 'flex-start';
      }
    },
    alignItems: 'center',
    padding: '0 10px',
    flex: 1,
  },
}));

export default useStyles;
