// import clsx from 'clsx';
// import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
// import StyledButton from 'components/general/StyledButton';
import { ISubsystem } from 'components/general/types/power';
import { TActiveEntityKeys } from './general/types';
// import useStyles from './styles';
// import COTSDialog from './COTSDialog';
// import { useCOTSContext } from 'providers';
// import { dialogOptions } from 'providers/COTSProvider';

interface IProps {
  entityKey: TActiveEntityKeys;
  subsystem: ISubsystem;
  buttonBorder?: boolean;
}

// TODO: COTSDialog temporarily disabled. Just comment this all back in (and remove the `return null`) to make it show
// up and work everywhere again

const COTSDialogButton = (props: IProps) => {
  return null;
  // const { entityKey, subsystem, buttonBorder } = props;
  // const classes = useStyles();
  // const { setDialogState } = useCOTSContext();
  // return (
  //   <>
  //     <StyledButton
  //       {...{ borderless: !buttonBorder }}
  //       framed
  //       onClick={() => setDialogState(dialogOptions.BROWSER)}
  //       className={clsx(classes.COTSDialogButton)}
  //     >
  //       <LibraryBooksIcon />
  //       <span className={classes.COTSDialogSpan}>Import from COTS Library</span>
  //     </StyledButton>
  //     <COTSDialog entityKey={entityKey} subsystem={subsystem} />
  //   </>
  // );
};

export default COTSDialogButton;
