import { makeStyles } from '@material-ui/core/styles';
import theme, { offMainBg } from 'theme';

const mainBackground = theme.palette.background.main;

interface IProps {
  passedMaxHeight: boolean;
}

const useStyles = makeStyles(() => ({
  fadeAndBorder: ({ passedMaxHeight }: IProps) => ({
    position: 'relative',
    paddingTop: passedMaxHeight ? 25 : 20,
    maxHeight: 500,
    '&:before': {
      content: '""',
      borderTop: '0.5px solid #dddddd',
      position: 'absolute',
      zIndex: 10,
      top: 20,
      left: 0,
      right: 0,
      height: '18px',
      ...(passedMaxHeight && {
        // shadow effect for scrolling, when passed max height triggering scroll functionality
        background: `linear-gradient(to bottom, ${mainBackground} 0%, ${offMainBg(0)} 100%)`,
        marginRight: 15,
      }),
    },

    '&:after': {
      content: '""',
      borderBottom: '0.5px solid #dddddd',
      position: 'absolute',
      zIndex: 10,
      bottom: '-1px',
      left: 0,
      right: 0,
      height: '40px',
      ...(passedMaxHeight && {
        // shadow effect for scrolling, when passed max height triggering scroll functionality
        background: `linear-gradient(to top, ${mainBackground} 0%, ${offMainBg(0)} 100%)`,
        marginRight: 15,
      }),
    },
  }),
  historyContainer: {
    maxHeight: 500,
    overflowY: 'auto',
  },
  commitContainer: ({ passedMaxHeight }: IProps) => ({
    '&:not(:first-of-type)': {
      borderTop: '0.5px solid #dddddd',
    },
    padding: 10,
    marginLeft: 10,
    ...(passedMaxHeight && {
      marginRight: 15,
    }),
  }),
}));

export default useStyles;
