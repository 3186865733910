import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'theme';

// all these things are necessary to overwrite MUI defaults and
// make it look like a button that doesn't move when clicked
const StyleLikeButton = (theme: ITheme) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  border: 'none',
  margin: '1px 1px 9px 1px',
  '& :hover:not(:focus)': {
    backgroundColor: theme.palette.primary.dark,
  },
});

const useStyles = makeStyles((theme) => ({
  createSubsystemForm: {
    width: '7.15rem',
    margin: '0.5rem 0 0 0',
  },
  createSubsystemFormInput: ({ focused }: { focused: boolean }) => ({
    ...(!focused && StyleLikeButton(theme)),
    borderRadius: 3,
    height: '100%',
    '& input': {
      transition: 'background-color 0.2s ease-in-out',
      borderRadius: 3,
    },
    '& input:not(:focus)': {
      cursor: 'pointer',
    },
  }),
}));

export default useStyles;
