import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import theme, { offWhite } from 'theme';

const iconColor = offWhite(0.8);
const powerProcessorIconDimensions = 65;
const overlayDimensions = 30;
const iconBorderWidth = 4;
const overlayPadding = 3;
const iconScaleFactor = 0.8;
const dottedBorder = `${iconBorderWidth}px dotted ${iconColor}`;

const overlayCenterCalculator = (large = false) => {
  const _result =
    Math.floor((powerProcessorIconDimensions - overlayDimensions) / 2) - overlayPadding;
  if (large) {
    return Math.floor(_result * iconScaleFactor);
  }
  return _result;
};

// need to type this object or TypeScript throws error
const overlayContainerStyles: CSSProperties = {
  backgroundColor: theme.palette.primary.dark,
  zIndex: 1,
  position: 'absolute',
  boxSizing: 'border-box',
};

const useStyles = makeStyles((theme) => ({
  powerProcessorContainer: {
    display: 'flex',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.dark,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[5],
    width: 100,
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
      // width is smaller at larger screens to account for the shift to 3 columns leading to less available space
      width: 60,
    },
  },
  roundedCornersContainer: {
    borderRadius: 5,
    border: `${iconBorderWidth}px solid ${iconColor}`,
    width: powerProcessorIconDimensions,
    height: powerProcessorIconDimensions,
    padding: '.25em',
    position: 'relative',
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
      width: powerProcessorIconDimensions * iconScaleFactor,
      height: powerProcessorIconDimensions * iconScaleFactor,
    },
  },
  innerContainer: {
    backgroundColor: iconColor,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 3,
  },
  iconContainer: {
    color: theme.palette.primary.dark,
    fontSize: overlayDimensions,
    [theme.breakpoints.up('lg')]: {
      fontSize: overlayDimensions * iconScaleFactor,
    },
  },
  xOverlayContainer: {
    ...overlayContainerStyles,
    width: `calc(100% + ${iconBorderWidth * 2}px)`,
    height: overlayDimensions,
    padding: `${overlayPadding}px 0px`,
    top: overlayCenterCalculator(),
    left: -iconBorderWidth,
    [theme.breakpoints.up('lg')]: {
      top: overlayCenterCalculator(true),
      height: overlayDimensions * iconScaleFactor,
    },
  },
  xOverlay: {
    borderRight: dottedBorder,
    borderLeft: dottedBorder,
    boxSizing: 'border-box',
    padding: '1px 0px',
    width: '100%',
    height: '100%',
  },
  yOverlayContainer: {
    ...overlayContainerStyles,
    height: `calc(100% + ${iconBorderWidth * 2}px)`,
    width: overlayDimensions,
    padding: `0px ${overlayPadding}px`,
    top: -iconBorderWidth,
    right: overlayCenterCalculator(),
    [theme.breakpoints.up('lg')]: {
      right: overlayCenterCalculator(true),
      width: overlayDimensions * iconScaleFactor,
    },
  },
  yOverlay: {
    borderTop: dottedBorder,
    borderBottom: dottedBorder,
    boxSizing: 'border-box',
    padding: '0px 1px',
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
