import { useState } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useDispatch } from 'react-redux';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { IErrorResponse } from 'components/general/types';
import { useFormikForm, useSnackbar } from 'hooks';
import * as Yup from 'yup';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { Grid } from '@material-ui/core';
import GuidanceCard from 'components/general/GuidanceCard';

const commitSchema = Yup.object().shape({
  commitMessage: Yup.string()
    .required('A commit message is required')
    .max(72, 'A commit message must be no more than 72 characters.'),
});

interface IProps {
  dialogControl: TEntityDialogControl<number>;
}

interface ICommitForm {
  commitMessage: string;
}

const defaultValues: ICommitForm = {
  commitMessage: '',
};

const guidance = {
  heading: 'Version Control: Commiting',
  body: [
    {
      chunk:
        "Committing changes allows you back up your saved changes and bookmark them with commit messages. It's recommended that you commit your changes whenver you significantly alter any modules in your branch.",
    },
    {
      chunk: 'Note that only committed changes will be included when merging branches.',
    },
  ],
};

const CommitDialog = ({ dialogControl }: IProps) => {
  const {
    dialogConfig: { entity: branchId, open },
    closeDialog,
  } = dialogControl;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { MissionVersion } = SatelliteApi;

  const { enqueueSnackbar } = useSnackbar();

  const addCommit = (values: ICommitForm) => {
    setLoading(true);
    dispatch(
      MissionVersion.actions.commit({
        ...values,
        id: branchId,
        successCallback: (response: { message: string }) => {
          enqueueSnackbar(response.message, { variant: 'success' });
          setLoading(false);
          extOnClose();
        },
        failureCallback: (response: IErrorResponse) => {
          enqueueSnackbar(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  const { formik } = useFormikForm<ICommitForm, ICommitForm>(
    defaultValues,
    addCommit,
    commitSchema,
    defaultValues
  );

  const { handleSubmit, getFieldProps, resetForm } = formik;
  const extOnClose = () => {
    closeDialog();
    setTimeout(() => resetForm(), 200);
  };

  return (
    <Dialog
      prompt="Add Commit"
      submitActionText="Commit"
      open={open}
      onClose={extOnClose}
      onSubmit={handleSubmit}
      loading={loading}
      large
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <LabeledInput
            multiline
            {...getFieldProps('commitMessage')}
            rows={4}
            type="text"
            placeholder="Commit Message"
            autoFocus
            label="Commit Message"
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <GuidanceCard guidance={guidance} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CommitDialog;
