import useStyles from './styles';
import { IData } from '..';

const LegendCircle = ({ legendPoint }: { legendPoint: IData }) => {
  const classes = useStyles(legendPoint);

  return <div className={classes.circle}></div>;
};

export default LegendCircle;
