import { useMemo } from 'react';
import useStyles from './styles';
import PowerStateArrow from '../PowerStateArrow';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { Tooltip } from '@material-ui/core';
import { ISubsystem } from 'components/general/types/power';

interface IProps {
  subsystem: ISubsystem;
}

const SubsystemGroup = (props: IProps) => {
  const { subsystem } = props;

  const components = subsystem.components;

  const active = useMemo(() => {
    for (const component of components) {
      if (component.powerConsumed) return true;
    }
    return false;
  }, [components]);

  const classes = useStyles({ active });

  return (
    <div className={classes.subsystemContainer}>
      <PowerStateArrow type="primary" fill={active} />
      <div className={classes.subsystem}>
        <BrokenImageIcon className={classes.subsystemIcon} />
        <Tooltip arrow title={subsystem.name}>
          <h3 className={classes.subsystemName}>{subsystem.name}</h3>
        </Tooltip>
      </div>
    </div>
  );
};

export default SubsystemGroup;
