import { makeStyles } from '@material-ui/core/styles';

const listItemNames = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const useStyles = makeStyles((theme) => ({
  conditionsList: {
    paddingLeft: 20,
  },
  opModeIcon: {
    minWidth: 35,
  },
  opModeName: {
    ...listItemNames,
    width: '20ch',
  },
  conditionIcon: {
    minWidth: 30,

    '&>svg': {
      height: 20,
      width: 'auto',
    },
  },
  conditionName: {
    ...listItemNames,
    width: '15ch',
  },
  activeIcon: {
    color: theme.palette.success.dark,
  },
}));

export default useStyles;
