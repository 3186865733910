import { SpacecraftAccent } from 'components/general/Accent/variants';

const BfVectorAccent = (props) => {
  const { children, ...remainingProps } = props;

  return (
    <SpacecraftAccent header="Body Frame Vector" {...remainingProps}>
      {children}
    </SpacecraftAccent>
  );
};

export default BfVectorAccent;
