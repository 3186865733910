import { makeGuidance } from 'hooks';

const useGuidance = makeGuidance({
  _default: {
    heading: 'Body Frame Vector Inputs',
    body: 'Complete the form to create a body frame vector.',
  },

  name: 'Input a descriptive name for the body frame vector and select a method to define the vector.',

  definitionType: {
    alias: 'name',
  },

  definitionParams: {
    theta: 'Define the body frame vector using spherical angles.',

    phi: {
      alias: 'definitionParams.theta',
    },

    vector: {
      body: 'Define the body frame vector using vector components. The input vector will be normalized to create the body frame vector.',
    },
  },
});
export default useGuidance;
