import WGroup from 'components/general/WGroup';
import Wizard from 'components/general/wizards/Wizard';
import { wGroupIndicesATTargets } from 'components/AgentTemplateEditView/menu/targets';

import TargetsSegment from './TargetsSegment';
import TargetGroupsSegment from './TargetGroupsSegment';

const CDHEditBoard = () => {
  return (
    <WGroup index={wGroupIndicesATTargets.TARGETS}>
      <Wizard>
        <TargetsSegment />
        <TargetGroupsSegment index={wGroupIndicesATTargets.TARGETS_TARGET_GROUPS} />
      </Wizard>
    </WGroup>
  );
};

export default CDHEditBoard;
