import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { IColumn } from 'components/general/types';
import guidance from './guidance';
import { ISolarArray } from 'components/general/types/power';
import { useActiveEntities } from 'hooks';
import Dialog from './Dialog';

interface IProps {
  index: string;
}

const solarArrayColumns: IColumn[] = [
  { title: 'Name', field: 'name' },
  { title: 'Number of Panels', render: (rowData: ISolarArray) => rowData.panels.length.toString() },
];

const SolarArraysSegment = (props: IProps) => {
  const { index } = props;
  const { solarArrays } = useActiveEntities();

  return (
    <EntityTableSegment<ISolarArray>
      title="Solar Arrays"
      index={index}
      guidance={guidance}
      entityColumns={solarArrayColumns}
      entityData={solarArrays}
      modelName="solar array"
      DialogComponent={Dialog}
    ></EntityTableSegment>
  );
};

export default SolarArraysSegment;
