import Wizard from 'components/general/wizards/Wizard';
import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import SolarCellsSegment from './SolarCellsSegment';
import SolarPanelsSegment from './SolarPanelsSegment';
import SolarArraysSegment from './SolarArraysSegment';

const PowerGenerationWizard = () => {
  return (
    <Wizard>
      <SolarCellsSegment index={wGroupIndicesATPower.POWER_PG_SC} />
      <SolarPanelsSegment index={wGroupIndicesATPower.POWER_PG_SP} />
      <SolarArraysSegment index={wGroupIndicesATPower.POWER_PG_SA} />
    </Wizard>
  );
};

export default PowerGenerationWizard;
