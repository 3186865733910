import { makeStyles } from '@material-ui/core/styles';

export const wizardRootStyles = {
  maxWidth: 1000,
  minWidth: 400,
  width: '100%',
};

const useStyles = makeStyles((theme) => ({
  root: wizardRootStyles,
  spacer: {
    ...theme.mixins.boardElementStyles,
  },
}));

export default useStyles;
