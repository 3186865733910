import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import useStyles from './styles';

export interface IProps {
  flipped?: boolean;
  type: 'primary' | 'secondary';
  fill?: boolean;
}

const PowerStateArrow = (props: IProps) => {
  const classes = useStyles(props);
  return (
    <div className={classes.arrowContainer}>
      <ArrowRightAltIcon preserveAspectRatio="none" classes={{ root: classes.arrow }} />
    </div>
  );
};

export default PowerStateArrow;
