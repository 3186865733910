import { makeGuidance } from 'hooks';

const batteryCellHeading = 'Battery Cells';

const batteryCellMainParagraph =
  'Battery cells are defined using their equivalent series resistance (ESR) curve, maximum charge and discharge currents, minimum allowed state of charge for safe operation, and their discharge curve, given by a series of state-of-charge (SOC) and open circuit voltage (VOC) points.';

const curveGuidance = {
  heading: batteryCellHeading,
  body: [
    {
      chunk: batteryCellMainParagraph,
    },
    {
      subHeading: 'Battery Cell Discharge Curve',
      chunk:
        "The battery cell's discharge curve given by the change in open circuit voltage as a function of state-of-charge. Specify points along the curve to define the cell's discharge behavior.",
    },
  ],
};

const maxCurrentGuidance = {
  heading: batteryCellHeading,
  body: [
    {
      chunk: batteryCellMainParagraph,
    },
    {
      subHeading: 'Maximum Charge and Discharge Currents',
      chunk:
        'The maximum charge and discharge currents are the maximum currents at which the battery cell can be charged and discharged. They are chosen to prevent damage to the battery cell and extend its lifetime.',
    },
  ],
};

export const batteryCellGuidance = {
  _default: {
    heading: batteryCellHeading,
    body: [
      {
        chunk: batteryCellMainParagraph,
      },
    ],
  },

  capacity: {
    heading: batteryCellHeading,
    body: [
      {
        chunk: batteryCellMainParagraph,
      },
      {
        subHeading: 'Capacity',
        chunk: 'The capacity of the battery cell defines how much charge it can store.',
      },
    ],
  },

  esr: {
    heading: batteryCellHeading,
    body: [
      {
        chunk: batteryCellMainParagraph,
      },
      {
        subHeading: 'Equivalent Series Resistance',
        chunk:
          "The equivalent series resistance (ESR) is a measure of the battery cell's internal resistance. This characterizes the energy dissipated by the battery as it is charged or discharged, which limits the amount of peak current it can deliver.",
      },
    ],
  },

  maxChargeCurrent: maxCurrentGuidance,

  maxDischargeCurrent: maxCurrentGuidance,

  minSoc: {
    heading: batteryCellHeading,
    body: [
      {
        chunk: batteryCellMainParagraph,
      },
      {
        subHeading: 'Minimum State of Charge',
        chunk:
          "The minimum state of charge is specified as a percentage of the battery cell's total capacity. It is chosen to prevent the battery cell from fully discharging and to extend its lifetime.",
      },
    ],
  },

  curve: {
    pointsSoc: curveGuidance,
    pointsVoc: curveGuidance,
  },
};

const useGuidance = makeGuidance(batteryCellGuidance);
export default useGuidance;
