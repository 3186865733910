import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.text.primary,
  },
  errorHeading: {
    color: theme.palette.error.light,
  },
  note: {
    marginTop: 0,
  },
}));

export default useStyles;
