import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledPasswordInput, {
  passwordSchema,
} from 'components/general/inputs/LabeledInput/LabeledPasswordInput';
import StyledButton from 'components/general/StyledButton';
import WaveContainer from 'components/general/WaveContainer';
import AuthWidget from 'components/general/widgets/AuthWidget';
import { EMAIL_REGEX } from 'config';
import { useFormikForm, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import * as Yup from 'yup';
import useStyles from './styles';

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .required('Your email is required')
    .max(320, 'Email must be no more than 320 characters')
    .matches(EMAIL_REGEX, 'Please enter a valid email address'),
});

const passwordResetSchema = Yup.object().shape({
  newPassword: passwordSchema,
});

const PasswordResetView = () => {
  const { token } = useParams();
  let history = useHistory();

  const {
    User: {
      actions: { requestPasswordReset, resetPassword },
    },
  } = SatelliteApi;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const onEmailSubmit = (values) => {
    setLoading(true);
    dispatch(
      requestPasswordReset({
        email: values.email,
        successCallback: () => {
          enqueueSnackbar(
            'Success!  If the provided email address matches what we have on file, you will receive an email with a reset link shortly.',
            { variant: 'success' }
          );
          setLoading(false);
        },
        failureCallback: () => {
          enqueueSnackbar(
            'Something went wrong.  Please make sure your email is valid and try again.'
          );
          setLoading(false);
        },
      })
    );
  };

  const onPasswordSubmit = (values) => {
    setLoading(true);
    dispatch(
      resetPassword({
        passwordResetToken: token,
        password: values.newPassword,
        successCallback: () => {
          enqueueSnackbar('Your password has been changed. Please login.', {
            variant: 'success',
          });
          setLoading(false);
          setTimeout(() => {
            history.push(Routes.LOGIN());
          }, 2000);
        },
        failureCallback: (response) => {
          let msg = response.error.message;
          if (msg.includes('passwordResetToken')) {
            msg =
              'Something went wrong.  Your link is likely expired.  Please restart the password reset process to obtain a new link.';
          }
          enqueueSnackbar(msg);
          setLoading(false);
        },
      })
    );
  };

  const onEmailForm = useMemo(() => token === ':token', [token]);

  const { formik } = useFormikForm(
    { email: '' },
    onEmailForm ? onEmailSubmit : onPasswordSubmit,
    onEmailForm ? emailSchema : passwordResetSchema
  );
  const { getFieldProps, handleSubmit } = formik;

  const backToLoginBtn = (
    <StyledButton onClick={() => history.push(Routes.LOGIN())} fullWidth framed>
      Back to login
    </StyledButton>
  );

  return (
    <WaveContainer>
      <AuthWidget>
        <form onSubmit={handleSubmit}>
          {onEmailForm ? (
            <>
              <h5 className={classes.heading}>Please enter your email address</h5>
              <p className={classes.prompt}>
                If your email matches an account we have on file, you will recieve an email with a
                personalized password reset link.
              </p>
              <LabeledInput
                {...getFieldProps('email')}
                label="Email"
                type="text"
                placeholder="Email"
                autoComplete="on"
              />
              <StyledButton type="submit" disabled={loading} loading={loading} tallMargin fullWidth>
                Submit
              </StyledButton>
              {backToLoginBtn}
            </>
          ) : (
            <>
              <h5 className={classes.heading}>Please enter a new password</h5>
              <LabeledPasswordInput {...getFieldProps('newPassword')} label="New Password" />
              <StyledButton type="submit" disabled={loading} loading={loading} tallMargin fullWidth>
                Submit
              </StyledButton>
              {backToLoginBtn}
            </>
          )}
        </form>
      </AuthWidget>
    </WaveContainer>
  );
};

export default PasswordResetView;
