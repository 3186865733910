import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

const padding = 10;
const height = 50;

const generalSlotProps = {
  borderRadius: theme.dimensions.inputs.borderRadius,
  marginBottom: 10,
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 6,
    display: 'flex',
  },
  decoration: {
    marginTop: height + padding,
    marginRight: 8,
    marginBottom: padding,
  },
  queue: {
    position: 'relative',
    width: '100%',
  },
  grid: {
    position: 'relative',
  },
  slot: {
    width: '100%',
    position: 'absolute',
  },
  slotContent: {
    ...generalSlotProps,
    cursor: 'move',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: theme.shadows[3],
    padding: padding,
    minHeight: height - padding * 2,
  },
  newSlot: {
    ...generalSlotProps,
    cursor: 'pointer',
    width: '100%',
    textAlign: 'left',
    border: '2px dashed ' + theme.palette.text.secondary,
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    minHeight: height,
    ...theme.typography.highlight1,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    display: 'flex',
  },
  badge: {
    marginLeft: 10,
  },
  select: {
    marginBottom: 15,
  },
  decorationForSelectVariant: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export default useStyles;
