import { makeGuidance } from 'hooks';

const useGuidance = makeGuidance({
  _default: {
    heading: 'Solar Arrays',
    body: [
      {
        chunk: 'Assign a name to the solar array and save it to add solar panels to it.',
      },
      {
        subHeading: 'Solar Panels',
        chunk:
          'Select the solar panels that compose the array. Panels can be composed of different solar cells, have different configurations, and be mounted on different surfaces.  Solar panels may only be associated to one solar array.  If you need to move a panel to a new array, please disassociate it from its current array first.',
      },
    ],
  },
});

export default useGuidance;
