// Solar Array Segment guidance
const guidance = {
  heading: 'Create and Edit Solar Arrays',
  body: [
    {
      chunk:
        'Solar arrays provide power to the satellite and charge the battery. Each solar array is composed of one of more panels in parallel, each of which is installed on an external surface of the spacecraft.',
    },
  ],
};

export default guidance;
