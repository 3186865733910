import { Fragment } from 'react';
import StyledLabel from 'components/general/StyledLabel';
import theme from 'theme/index';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import useStyles from '../styles';
import _ from 'lodash';
import moment from 'moment-timezone';
import UTCUtils from '../UTCUtils';
import { useInReadOnlyBranch } from 'hooks';

moment.tz.setDefault('Etc/UTC');

const errorStyle = { borderColor: theme.palette.error.main };

export const FORMAT = 'HH:mm:ss';

const LabeledTimePicker = (props) => {
  const { label, name, errors, control, guide, optional, setFieldValue, ...remainingProps } = props;
  const inReadOnlyBranch = useInReadOnlyBranch();

  // I used this to guide me with the date pickers -
  // https://codesandbox.io/s/react-hook-form-controller-h4h6v?file=/src/index
  // - which is where this style of error handling came from:
  const error = Object.prototype.hasOwnProperty.call(errors, name) && errors[name].message;

  const classes = useStyles();

  return (
    <Fragment>
      <StyledLabel>{optional ? `${label} (optional)` : label}</StyledLabel>
      <MuiPickersUtilsProvider libInstance={moment} utils={UTCUtils} locale="utc">
        <KeyboardTimePicker
          name={name}
          {...remainingProps}
          fullWidth
          autoOk
          ampm={false}
          onFocus={guide && ((e) => guide(e, name, label))}
          onOpen={guide && ((e) => guide(e, name, label))}
          //onBlur={guide && ((e) => guide(e))}
          //onClose={guide && ((e) => guide(e))}
          // Formik requires using setFieldValue for DateTime pickers, which is passed from our extended getFieldProps
          onChange={(value) => {
            if (setFieldValue) {
              setFieldValue(name, value);
            }
          }}
          error={!!error}
          variant="inline"
          format={FORMAT}
          placeholder={FORMAT}
          className={classes.root}
          style={errors && _.get(errors, name) ? errorStyle : null}
          invalidDateMessage={''}
          views={['hours', 'minutes', 'seconds']}
          disabled={props.disabled || inReadOnlyBranch}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
};

export default LabeledTimePicker;
