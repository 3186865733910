import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spacecraftDialogInlayButton: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    
    '& svg': {
      color: theme.palette.primary.main,
      marginRight: 5,
      marginLeft: 2,
    },
  },
}));

export default useStyles;
