import styled from 'styled-components';

const ViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default ViewContainer;
