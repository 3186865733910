import LabeledInput from 'components/general/inputs/LabeledInput';
import StyledButton from 'components/general/StyledButton';
import Widget from 'components/general/widgets/Widget';
import { EMAIL_REGEX } from 'config';
import { useFormikForm, useSnackbar, useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import AuthDialog from './AuthDialog';

const defaultValues = {
  firstName: '',
  lastName: '',
  organization: '',
  email: '',
};

const requiredMessage = 'All fields are required.';
// Validation Schema
const userSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(requiredMessage)
    .max(128, 'First name must be no more than 128 characters.'),
  lastName: Yup.string()
    .required(requiredMessage)
    .max(128, 'Last name must be no more than 128 characters.'),
  email: Yup.string()
    .required('Your email is required.')
    .max(320, 'Email must be no more than 320 characters.')
    .matches(EMAIL_REGEX, 'Please enter a valid email address.'),
  organization: Yup.string()
    .required(requiredMessage)
    .max(128, 'Organization must be no more than 128 characters.'),
});

const UserInfoWidget = () => {
  const {
    User: {
      actions: { updateUser },
    },
  } = SatelliteApi;
  const user = useUser();
  // state initialized to blank
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const save = () => {
    setDialogOpen(true);
  };

  const handleModalClose = () => {
    setDialogOpen(false);
  };

  const updateInfo = (values) => {
    setLoading(true);
    dispatch(
      updateUser({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        organization: values.organization,
        successCallback: (response) => {
          // success callback
          handleModalClose();
          const successMsg = 'Successfully updated account information.';
          if (values.email !== user.email) {
            enqueueSnackbar(
              successMsg +
                ' Please verify your new email by clicking the link we just sent to your updated address. Until then, your old email will stay active.',
              {
                variant: 'success',
              }
            );
          } else {
            enqueueSnackbar(successMsg, {
              variant: 'success',
            });
          }
          setLoading(false);
        },
        failureCallback: (response) => {
          // update user failure callback
          if (response.error.code === 'UNIQUENESS_VIOLATION') {
            enqueueSnackbar('An account with that email address already exists.');
          } else {
            enqueueSnackbar(response.error.message);
          }
          setLoading(false);
        },
      })
    );
  };

  const { enqueueSnackbar } = useSnackbar();
  const { formik } = useFormikForm(defaultValues, dialogOpen ? updateInfo : save, userSchema, {
    firstName: user.firstName,
    lastName: user.lastName,
    organization: user.organization,
    email: user.email,
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <Fragment>
      <Widget title={'User information'} maxWidth={400} minWidth={200}>
        <form onSubmit={handleSubmit}>
          <LabeledInput
            label="First Name"
            placeholder="First Name"
            {...getFieldProps('firstName')}
          ></LabeledInput>
          <LabeledInput
            label="Last Name"
            placeholder="Last Name"
            {...getFieldProps('lastName')}
          ></LabeledInput>
          <LabeledInput
            label="Organization"
            placeholder="Organization"
            {...getFieldProps('organization')}
          ></LabeledInput>
          <LabeledInput
            label="Email"
            placeholder="Email"
            {...getFieldProps('email')}
          ></LabeledInput>
          <StyledButton type="submit" disabled={loading} tallMargin fullWidth>
            Save
          </StyledButton>
        </form>
      </Widget>
      <AuthDialog
        user={user}
        dialogOpen={dialogOpen}
        onSuccess={handleSubmit}
        onClose={handleModalClose}
      />
    </Fragment>
  );
};

export default UserInfoWidget;
