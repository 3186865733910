import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

export const roboBlue = '#5D83BE';
export const backgroundLighter = '#292D3D';

export const gradientBorder = {
  background:
    'linear-gradient(45deg, ' +
    theme.palette.secondary.light +
    ',' +
    theme.palette.primary.dark +
    ')',
  borderRadius: 3,
  boxShadow: theme.shadows[5],
  padding: 1,
};

export const typographyLegal = {
  fontSize: 14,
  [theme.breakpoints.down('md')]: {
    fontSize: 12,
  },
};

const browserHeaderHeight = 20;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    color: theme.palette.background.contrastText,
    background: theme.palette.background.dark,
    '& h1': {
      fontSize: 50,
      [theme.breakpoints.down('md')]: {
        fontSize: 42,
      },
    },
    '& h2': {
      fontSize: 38,
      [theme.breakpoints.down('md')]: {
        fontSize: 35,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 28,
      },
    },
    '& h3': {
      fontSize: 26,
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 22,
      },
    },
    '& h3.accent': {
      fontSize: 32,
      color: roboBlue,
      fontFamily: 'Roboto Condensed',
      [theme.breakpoints.down('md')]: {
        fontSize: 30,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 25,
      },
    },
    '& h4': {
      fontSize: 24,
      [theme.breakpoints.down('md')]: {
        fontSize: 22,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    '& h5': {
      fontSize: 20,
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    '& p': {
      fontSize: 16,
      fontFamily: 'Montserrat',
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
  },

  centerHeading: {
    textAlign: 'center',
    '& h1': {
      margin: '85px 0px',
      marginBottom: 0,
    },
    '& h2': {
      marginTop: 80,
    },
    '& h4': {
      maxWidth: 800,
      margin: '25px auto',
    },
  },

  section: {
    marginTop: 150,
  },

  brandNavLink: {
    paddingTop: 12,
    '& img': {
      width: 350,
      height: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: 240,
      },
    },
  },

  offerContainer: {
    background:
      'linear-gradient(' +
      theme.palette.secondary.light +
      ',#6869D3 ,#3D3E74,' +
      theme.palette.background.dark +
      ')',
  },

  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  authLinksContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 24,
    paddingRight: 0,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '6px',
      height: 65,
    },
  },

  authLink: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 12px 5px 12px',
    borderRadius: '3px',
    width: '45px',
    border: '1px solid white',
    textDecoration: 'none',
  },

  authLinkFramed: {
    [theme.breakpoints.up('sm')]: {
      marginRight: 12,
    },
    backgroundColor: 'transparent',
    color: 'white',
  },

  authLinkFilled: {
    backgroundColor: 'white',
    color: '#9b6dff',
  },

  getStartedLink: {
    marginTop: 40,
    padding: '12px 20px',
    fontSize: 24,
    width: 'max-content',
    transition: 'background-color .25s, font-weight .25s',
    transitionTimingFunction: 'ease-in, step-start',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .2)',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      padding: '10px 18px',
    },
    border: '1px solid rgba(255, 255, 255, .5)',
    background: 'none',
    position: 'relative',
    outline: 'none',
    boxSizing: 'border-box',
    textDecoration: 'none',
    '&::before': {
      boxSizing: 'inherit',
      position: 'absolute',
      content: '""',
      border: '2px solid transparent',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    },
    '&::after': {
      boxSizing: 'inherit',
      position: 'absolute',
      content: '""',
      border: '2px solid transparent',
      width: 0,
      height: 0,
      bottom: 0,
      right: 0,
    },
    '&:hover::before': {
      width: '100%',
      height: '100%',
      borderTopColor: '#fff',
      borderRightColor: '#fff',
      transition: 'width 0.2s ease-out, height 0.2s ease-out 0.3s',
    },
    '&:hover::after': {
      width: '100%',
      height: '100%',
      borderBottomColor: '#fff',
      borderLeftColor: '#fff',
      transition:
        'border-color 0s ease-out 0.5s, width 0.2s ease-out 0.5s, height 0.2s ease-out .8s',
    },
  },

  daysRemainingContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '100px auto',
    marginBottom: 0,
    alignItems: 'center',
    '& > *': {
      margin: 0,
      marginTop: '1rem',
    },
  },

  // Safari's outline css property does not obey border radius, so this is the alternative
  outline: {
    border: `1px solid ${theme.palette.background.contrastText}`,
    borderRadius: '50%',
    boxSizing: 'border-box',
    position: 'absolute',
    height: '21.5em',
    width: '21.6em',
    top: 3,
    right: 3,
  },

  daysRemainingCounter: {
    display: 'inline-flex',
    position: 'relative',
    boxSizing: 'border-box',
  },

  daysRemaining: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& h2': {
      margin: 0,
    },
  },

  daysRemainingProgress: {
    color: 'inherit',
    filter: 'drop-shadow( -2px 0px 4px rgba(0, 0, 0, .3))',
  },

  backToAccount: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginTop: 10,
    color: 'white',
    '& h5': {
      color: 'inherit',
    },
  },

  floatToEndOnMobile: {
    [theme.breakpoints.down('xs')]: {
      order: 3,
    },
  },

  infoHook: {
    display: 'flex',
    justifyContent: 'center',
    '& > h2': {
      margin: '2em 0em',
      maxWidth: '80%',
    },
  },

  contactUsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  contactUsButton: {
    cursor: 'pointer',
  },

  productViewWrapper: {
    marginTop: 120,
    margin: '0 auto',
    textAlign: 'center',

    '& h2': {
      maxWidth: 900,
      marginBottom: 40,
      textAlign: 'left',
      '& strong': {
        color: roboBlue,
        fontWeight: 600,
      },
    },
  },

  productView: {
    width: '100%',
    verticalAlign: 'middle',
    minHeight: 150,
    minWidth: 200,
  },

  productViewCutoff: {
    maxWidth: 700,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 444,
    },
  },

  browserView: {
    backgroundColor: '#dee1e6',
    borderRadius: 3,
    padding: 2,
    paddingTop: browserHeaderHeight,
    display: 'inline-block',
    margin: '0 auto',
    marginBottom: 10,
    boxShadow: theme.shadows[5],
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: browserHeaderHeight * 0.8,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: browserHeaderHeight * 0.5,
      padding: 1,
    },

    '& .control': {
      height: browserHeaderHeight / 2,
      width: browserHeaderHeight / 2,
      borderRadius: '50%',
      position: 'absolute',
      top: browserHeaderHeight / 4,
      [theme.breakpoints.down('sm')]: {
        top: (browserHeaderHeight / 4) * 0.8,
        height: (browserHeaderHeight / 2) * 0.8,
        width: (browserHeaderHeight / 2) * 0.8,
      },
      [theme.breakpoints.down('xs')]: {
        top: (browserHeaderHeight / 4) * 0.5,
        height: (browserHeaderHeight / 2) * 0.5,
        width: (browserHeaderHeight / 2) * 0.5,
      },
    },

    '& .control-red': {
      left: 5,
      backgroundColor: '#f10001',
    },

    '& .control-yellow': {
      left: 5 + 3 + browserHeaderHeight / 2,
      backgroundColor: '#f9d50c',
      [theme.breakpoints.down('sm')]: {
        left: (5 + 3 + browserHeaderHeight / 2) * 0.8,
      },
      [theme.breakpoints.down('xs')]: {
        left: 12,
      },
    },

    '& .control-green': {
      left: 5 + (3 + browserHeaderHeight / 2) * 2,
      backgroundColor: '#02d806',
      [theme.breakpoints.down('sm')]: {
        left: (5 + (3 + browserHeaderHeight / 2) * 2) * 0.8,
      },
      [theme.breakpoints.down('xs')]: {
        left: 20,
      },
    },
  },

  sellingPointsContainer: {
    marginTop: 100,
    marginBottom: 100,
    textAlign: 'left',
    overflow: 'hidden',
    '& h2': {
      margin: '10px 0px',
      maxWidth: 600,
      marginTop: 5,
      position: 'relative',
      zIndex: 10,
      '&.wrap-5': {
        maxWidth: 500,
      },
      '&.wrap-4': {
        maxWidth: 400,
      },
    },
    '& h3': {
      position: 'relative',
      zIndex: 10,
    },
  },

  sellingPoint: {
    position: 'relative',
    padding: '20px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },

  sellingPointDescription: {
    fontSize: 24,
    paddingLeft: 20,
    borderLeft: '4px solid ' + roboBlue,
    position: 'relative',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },

  sellingPointIcon: {
    fontSize: 180,
    color: '#8e80e5',
    position: 'absolute',
    right: 0,
    top: 60,
    opacity: '30%',
  },

  dataStorageBox: {
    backgroundColor: backgroundLighter,
    border: `1px solid ${roboBlue}`,
    borderRadius: 3,
    width: 'max-content',
    marginTop: 20,
    padding: 10,
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '5px 0px',
      '& h3': {
        fontSize: 'inherit',
      },
    },
  },

  checkMark: {
    fontSize: 21,
    color: roboBlue,
    padding: '0px 10px',
  },

  tlItem: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },

  tlItemHeader: {
    margin: 0,
  },

  tlContent: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }),

  tlContentWithHeader: (props) => ({
    marginTop: 0,
    paddingBottom: !props.inline ? 7 : 0,
  }),

  tlOppositeContent: {
    margin: 'auto 0',
  },

  tlContentLeft: (props) => ({
    alignItems: 'flex-end',
    paddingRight: !props.inline && 16,
  }),

  tlContentRight: (props) => ({
    alignItems: 'flex-start',
    paddingLeft: !props.inline && 16,
  }),

  tlContentHideOnMobile: () => ({
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }),

  tlDot: {
    padding: 8,
  },

  tlDotFilled: {
    backgroundColor: theme.palette.secondary.light,
  },

  tlDotOutlined: {
    borderColor: theme.palette.secondary.light,
  },

  tlReleaseDate: {
    maxWidth: 140,
  },

  moduleWrapper: {
    display: 'flex',
    width: 410,
    borderRadius: 3,
    margin: '5px 0',
    ...gradientBorder,
    [theme.breakpoints.down('md')]: {
      width: 350,
    },
  },

  logoImage: {
    background: 'linear-gradient(44deg, #966CF9, #966BF9,#5B68C8, #5A69C8)',
    width: 97,
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      width: 80,
    },
  },

  moduleDetailsWrapper: {
    backgroundColor: backgroundLighter,
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  },

  moduleDetails: {
    '& h2': {
      fontSize: 28,
      marginBottom: 6,
    },
    '& h4': {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      '& h2': {
        fontSize: 22,
        marginBottom: 3,
      },
      '& h4': {
        fontSize: 16,
      },
    },
  },

  additionalModulesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  videoContainer: {
    position: 'relative',
    margin: '0 auto',
    maxWidth: 900,
    width: '90%',
    paddingBottom: '42.25%',
    height: 0,
    boxShadow: theme.shadows[5],
  },

  introVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

  closer: {
    marginBottom: 75,
  },

  legal: {
    margin: '0 auto',
    textAlign: 'center',
    ...typographyLegal,
    paddingBottom: 10,
  },
}));

export default useStyles;
