import { Fragment, useState } from 'react';
import StyledButton from 'components/general/StyledButton';
import Widget from 'components/general/widgets/Widget';
import AuthDialog from './AuthDialog';
import LabeledPasswordInput, {
  passwordSchema,
} from 'components/general/inputs/LabeledInput/LabeledPasswordInput';
import * as Yup from 'yup';
import { useFormikForm, useUser, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useDispatch } from 'react-redux';

const defaultValues = {
  newPassword: '',
};

const newPasswordSchema = Yup.object().shape({
  newPassword: passwordSchema,
});

const PasswordWidget = () => {
  const {
    User: {
      actions: { updateUser },
    },
  } = SatelliteApi;
  const user = useUser();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const save = () => {
    setDialogOpen(true);
  };

  const updatePassword = (values, resetForm) => {
    setLoading(true);
    dispatch(
      updateUser({
        password: values.newPassword,
        successCallback: () => {
          // success callback
          handleModalClose();
          enqueueSnackbar('Password updated successfully.', {
            variant: 'success',
          });
          setLoading(false);
          resetForm();
        },
        failureCallback: (response) => {
          // update user failure callback
          enqueueSnackbar(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  const onSubmit = (values, resetForm) => {
    // if dialog is already open, update the password, otherwise open the dialog
    dialogOpen ? updatePassword(values, resetForm) : save();
  };

  const { formik } = useFormikForm(defaultValues, onSubmit, newPasswordSchema, { newPassword: '' });
  const { getFieldProps, handleSubmit, resetForm } = formik;

  const handleModalClose = () => {
    setDialogOpen(false);
    resetForm();
  };

  return (
    <Fragment>
      <Widget title={'Update password'} maxWidth={400} minWidth={200}>
        <form onSubmit={handleSubmit}>
          <LabeledPasswordInput {...getFieldProps('newPassword')} label="New Password" />
          <StyledButton type="submit" disabled={loading} tallMargin fullWidth>
            Save
          </StyledButton>
        </form>
      </Widget>
      <AuthDialog
        user={user}
        dialogOpen={dialogOpen}
        onSuccess={handleSubmit}
        onClose={handleModalClose}
      />
    </Fragment>
  );
};

export default PasswordWidget;
