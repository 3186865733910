import styled from 'styled-components';

const StyledLabel = styled.label`
  margin-top: 4px;
  ${(props) => props.theme.typography.highlight2};
  align-self: flex-start;

  color: ${(props) =>
    props.monotone
      ? props.theme.palette.primary.main
      : props.disabled
      ? props.theme.palette.action.disabled
      : props.theme.palette.background.contrastText};
`;

export default StyledLabel;
