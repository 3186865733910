import { NavLink } from 'react-router-dom';
import { logo } from 'multimedia/brand';
import useStyles from './styles';
import WaveContainer from 'components/general/WaveContainer';

/* Not Found Page
 * Displayed whenever route is unresolved
 */
function NotFound() {
  const classes = useStyles();

  return (
    <WaveContainer>
      <NavLink exact to="/">
        <img src={logo} alt="logo" className={classes.brand} />
      </NavLink>
      <div className={classes.content}>
        <h1 className={classes.status}>
          <i>404</i>
        </h1>
        <i>
          <h2 className={classes.message}>PAGE NOT FOUND</h2>
        </i>
      </div>
    </WaveContainer>
  );
}

export default NotFound;
