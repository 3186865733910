export const norm = (x, y, z) => {
  return Math.pow(x * x + y * y + z * z, 0.5);
};

export const sphericalToCartesian = (theta, phi) => {
  return [Math.sin(theta) * Math.cos(phi), Math.sin(theta) * Math.sin(phi), Math.cos(theta)];
};

export const rad2Deg = (rad) => {
  return rad && (rad * 180) / Math.PI;
};

export const deg2Rad = (rad) => {
  return rad && (rad * Math.PI) / 180;
};

export const checkAligned = (vector1, vector2) => {
  const unitVector1 = normalize(vector1);
  const unitVector2 = normalize(vector2);

  // Round to 5 decimal places
  return (
    vectorNorm(unitVector1.map((i, ind) => parseFloat((i + unitVector2[ind]).toFixed(5)))) === 0 ||
    vectorNorm(unitVector1.map((i, ind) => parseFloat((i - unitVector2[ind]).toFixed(5)))) === 0
  );
};

export const normalize = (vector) => {
  return vector.map((i) => i / vectorNorm(vector));
};

export const vectorNorm = (vector) => {
  return Math.sqrt(Math.pow(vector[0], 2) + Math.pow(vector[1], 2) + Math.pow(vector[2], 2));
};
