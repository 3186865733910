import Wizard from 'components/general/wizards/Wizard';
import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import BatteryCellsSegment from './BatteryCellsSegment';
import BatteryPacksSegment from './BatteryPacksSegment';
import BatterySystemSegment from './BatterySystemSegment';

const PowerProcessingWizard = () => {
  return (
    <Wizard>
      <BatteryCellsSegment index={wGroupIndicesATPower.POWER_ES_BC} />
      <BatteryPacksSegment index={wGroupIndicesATPower.POWER_ES_BP} />
      <BatterySystemSegment index={wGroupIndicesATPower.POWER_ES_BS} />
    </Wizard>
  );
};

export default PowerProcessingWizard;
