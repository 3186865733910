import { Fragment, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import GuidanceCard from 'components/general/GuidanceCard';
import useStyles from './styles';
import StyledButton from 'components/general/StyledButton';

// Necessary to enable safe form nesting in children due to react-dom upgrade from 16->17
const FormContents = (props) => {
  let {
    title,
    guidance,
    onClose,
    onSubmit,
    onSecondaryAction,
    submitActionText,
    secondaryActionText,
    loading,
    children,
    disableSubmit,
  } = props;
  const classes = useStyles();
  if (!Array.isArray(children)) children = [children];
  const rightChildren = useMemo(() => children.filter((c) => c.props?.rightSide), [children]);
  const leftChildren = useMemo(
    () => children.filter((c) => c.props && !c.props.rightSide),
    [children]
  );
  return (
    <Fragment>
      <div className={classes.panelContainer}>
        <h3 className={classes.panelHeader}>{title}</h3>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {leftChildren}
        </Grid>
        <Grid item xs={12} md={6}>
          <GuidanceCard guidance={guidance} />
          {rightChildren}
        </Grid>
      </Grid>
      <div className={classes.footer}>
        {(onClose || onSecondaryAction) && (
          <StyledButton
            type="button"
            framed
            onClick={onSecondaryAction ? onSecondaryAction : onClose}
          >
            {secondaryActionText ? secondaryActionText : 'Cancel'}
          </StyledButton>
        )}
        {onSubmit && (
          <StyledButton
            disabled={loading || disableSubmit}
            loading={loading}
            replaceSpinner
            type="submit"
          >
            {submitActionText ? submitActionText : 'Confirm'}
          </StyledButton>
        )}
      </div>
    </Fragment>
  );
};

const Panel = (props) => {
  const { onSubmit } = props;
  return onSubmit ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (onSubmit) {
          onSubmit();
        }
      }}
    >
      <FormContents {...props} />
    </form>
  ) : (
    <FormContents {...props} />
  );
};

export default Panel;
