import { makeGuidance } from 'hooks';

import genericThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/genericThermalGuidance';

const thermalInterfaceMaterialsPermanentGuidance =
  'Thermal Interface Materials are used to describe the heat flow resistance between connected items on the spacecraft.';

export const useGuidance = makeGuidance({
  _default: {
    heading: 'Thermal Interface Materials',
    body: [
      {
        chunk:
          'Define a Thermal Interface Material with a thickness and the resistivity of the material.',
      },
    ],
  },
  ...genericThermalGuidance,
});

const thermalInterfaceMaterialsSegmentGuidance = {
  heading: 'Thermal Interface Materials',
  body: [
    {
      chunk: thermalInterfaceMaterialsPermanentGuidance,
    },
  ],
};

export default thermalInterfaceMaterialsSegmentGuidance;
