import { Moment } from 'moment';
import * as Yup from 'yup';

const dateChecker = (val: string | Moment) => {
  if (!val) return false;

  const dateRegex =
    /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{0,4}Z|\d{4}-\d{2}-\d{2} {2}\d{2}:\d{2}:\d{2})$/;
  if (typeof val === 'string') return dateRegex.test(val);
  else return dateRegex.test(val.toISOString());
};

const validation = Yup.object().shape({
  startTime: Yup.mixed()
    .test('test-moment', 'Select or enter a valid start date and time.', dateChecker)
    .required('Select a start date and time for the simulation.'),

  stopTime: Yup.mixed()
    .test('test-moment', 'Select or enter a valid stop date and time.', dateChecker)
    .required('Select a stop date and time for the simulation.'),
});

export default validation;
