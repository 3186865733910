import { makeGuidance } from 'hooks';
import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';

const batteryCellTechnology = {
  heading: 'Battery Cell and Series/Parallel Configuration',
  body: [
    {
      chunk: 'Select the Battery Cell and input the series and parallel configuration of the pack.',
    },
    {
      subHeading: 'Battery Cell Configuration',
      chunk:
        'Input the number of battery cells connected in series (a "string") and the number of series strings connected in parallel.',
    },
  ],
};

export const batteryPackGuidance = {
  _default: {
    heading: 'Battery Pack',
    body: [
      {
        chunk:
          'Battery packs are assembled using one or more identical battery cell which are configured in series and/or parallel.',
      },
    ],
  },

  numSeries: batteryCellTechnology,

  numParallel: batteryCellTechnology,

  cell: batteryCellTechnology,
  ...componentThermalGuidance,
};

const useGuidance = makeGuidance(batteryPackGuidance);
export default useGuidance;
