import WizardSegment from 'components/general/wizards/WizardSegment';
import { wGroupIndicesATThermal } from 'components/AgentTemplateEditView/menu/thermal';
import { ThermalStateWidget } from 'components/AgentAnalyzeView/AnalyzeBoards/ThermalAnalyzeBoards/playback';
import { useActiveEntities } from 'hooks';
import guidance from './guidance';

const ThermalInterfacesSegment = () => {
  const { model } = useActiveEntities();
  return (
    <WizardSegment
      title="Thermal Interfaces"
      loading={false}
      disableSubmit={false}
      guidance={guidance}
      index={wGroupIndicesATThermal.THERM_INTER}
      xray={model.ThermalInterface.all()}
    >
      {/* no widget for thermal state, as it's already included in segment */}
      <ThermalStateWidget model={model} noWidget editable />
    </WizardSegment>
  );
};

export default ThermalInterfacesSegment;
