import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  epsWidgetContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  solarArraysContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0rem 1rem',
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
    width: '40%',
  },

  subsystemsAndBatteryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
  },
}));

export default useStyles;
