import { forwardRef } from 'react';
import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';

const StyledAccordionTemp = styled(Accordion)`
  &.MuiPaper-root {
    flex: 1;
    background-color: ${(props) => props.theme.palette.background.main};
    width: 100%;
    ${(props) =>
      props.$focus && `outline: 1px solid ${props.theme.palette.background.contrastText};`}
  }

  hr {
    margin: 0px 20px 20px;
  }

  div > span.MuiIconButton-label > svg.MuiSvgIcon-root {
    fill: ${(props) => props.theme.palette.background.contrastText};
  }
`;

export const NestedStyledAccordionTemp = styled(StyledAccordionTemp)`
  &.MuiPaper-root {
    background-color: ${(props) => props.theme.palette.background.paper};
    box-shadow: ${(props) => props.theme.shadows[5]};
  }
`;

// Components above inherited below so can disable elavation by default

const StyledAccordion = forwardRef((props, ref) => {
  return <StyledAccordionTemp elevation={props.elevation || 0} ref={ref} {...props} />;
});
StyledAccordion.displayName = 'StyledAccordion';

export const NestedStyledAccordion = (props) => {
  return <NestedStyledAccordionTemp elevation={props.elevation || 0} {...props} />;
};

export default StyledAccordion;
