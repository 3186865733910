import WGroup from 'components/general/WGroup';
import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import SubsystemLoadingWizard from './SubsystemLoadingWizard';
import SubsystemLoadingSummary from './SubsystemLoadingSummary';
import { Box } from '@material-ui/core';
import { useActiveEntities } from 'hooks';

const SubsystemLoadingEditBoard = () => {
  const { operationalModes } = useActiveEntities();

  return (
    <WGroup index={wGroupIndicesATPower.POWER_SSL}>
      <Box display="flex" flexWrap="wrap">
        <SubsystemLoadingWizard />
        {operationalModes.length > 0 && <SubsystemLoadingSummary />}
      </Box>
    </WGroup>
  );
};

export default SubsystemLoadingEditBoard;
