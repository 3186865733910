import theme, { borderRadius } from 'theme';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const nodeFieldSetStyle = {
  background: theme.palette.background.light,
  borderRadius,
  margin: '.25rem 0',
  padding: '0 .25rem .15rem .25rem',
  border: 'none',
};

export const nodeFieldSetLegendStyle = {
  border: `1px solid ${theme.palette.background.dark}`,
  background: theme.palette.background.light,
  fontSize: '.4rem',
  borderRadius,
  color: theme.palette.primary.light,
};

export const ellipsesOverflow: CSSProperties = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};
