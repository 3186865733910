import { ConOpsTimeSeriesWGroup } from './timeSeries';
import { wGroupIndicesAgentCdh } from 'components/AgentAnalyzeView/menu/cdh';

const AnalyzeBoards = () => {
  return (
    <>
      <ConOpsTimeSeriesWGroup index={wGroupIndicesAgentCdh.OP_MODES} />
    </>
  );
};
export default AnalyzeBoards;
