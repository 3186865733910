import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sankeyWrapper: (props: { height: number }) => ({
    height: props.height,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
}));

export default useStyles;
