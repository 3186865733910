import { makeStyles } from '@material-ui/core/styles';

const strokeWidth = '3px';

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    top: '50%',
    width: '50%',
    margin: 'auto',

    '-webkit-transform': 'translateY(-50%)',
    '-moz-transform': 'translateY(-50%)',
    '-o-transform': 'translateY(-50%)',
    '-ms-transform': 'translateY(-50%)',

    textAlign: 'left',

    paddingBottom: 10,
  },

  status: {
    margin: 0,

    fontSize: 200,
    fontWeight: 500,

    '& i': {
      backgroundImage:
        'linear-gradient(225deg,' +
        theme.palette.primary.dark +
        ',' +
        theme.palette.secondary.main +
        ')',

      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      '-moz-background-clip': 'text',
      '-o-background-clip': 'text',
      '-ms-background-clip': 'text',

      '-webkit-text-fill-color': 'transparent',
      '-moz-text-fill-color': 'transparent',
      '-o-text-fill-color': 'transparent',
      '-ms-text-fill-color': 'transparent',

      '-webkit-text-stroke-width': strokeWidth,
      '-moz-text-stroke-width': strokeWidth,
      '-o-text-stroke-width': strokeWidth,
      '-ms-text-stroke-width': strokeWidth,

      '-webkit-text-stroke-color': theme.palette.background.main,
      '-moz-text-stroke-color': theme.palette.background.main,
      '-o-text-stroke-color': theme.palette.background.main,
      '-ms-text-stroke-color': theme.palette.background.main,
    },
  },

  message: {
    margin: 0,

    color: theme.palette.background.main,
    fontSize: 90,

    textShadow: '7px 7px 5px rgba(0, 0, 0, 0.15)',
  },

  brand: {
    position: 'absolute',

    top: 20,
    left: 20,

    height: 80,
    width: 'auto',
  },
}));

export default useStyles;
