import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { useRef } from 'react';
import theme from 'theme';

const SnackbarProvider = ({ children }) => {
  const textStyles = {
    color: `${theme.palette.background.contrastText} !important`,
    '& svg': {
      fill: `${theme.palette.background.contrastText} !important`,
    },
  };
  const useStyles = makeStyles(({ palette }) => ({
    root: {
      maxWidth: 425,
    },
    variantSuccess: {
      backgroundColor: `${palette.success.main} !important`,
      ...textStyles,
    },
    variantError: {
      backgroundColor: `${palette.error.main} !important`,
      ...textStyles,
    },
  }));

  const ref = useRef();
  const classes = useStyles();
  return (
    <NotistackSnackbarProvider
      ref={ref}
      classes={{
        root: classes.root,
        variantSuccess: classes.variantSuccess,
        variantError: classes.variantError,
      }}
      maxSnack={3}
      hideIconVariant={true}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={6000}
      action={(key) => (
        <IconButton onClick={() => ref.current.closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
