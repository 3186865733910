import { makeGuidance } from 'hooks';


const timeHeading = 'Edit Simulation Time Period';

const timeMainParagraph = 'Enter times in UTC for the start and end of the simulation.';

export const timeGuidance = {
  _default: {
    heading: timeHeading,
    body: [
      {
        chunk: timeMainParagraph,
      },
    ],
  },
};

const useGuidance = makeGuidance(timeGuidance);
export default useGuidance;
