import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  componentAccordion: {
    backgroundColor: theme.palette.background.paper,
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
      padding: 0,
    },
  },
  // !important tags necessitated to override styled component styles
  componentAccordionExpanded: {
    marginBottom: '32px !important',
  },
  componentAccordionDivider: {
    margin: ' 0 16px 8px !important',
  },
  componentAccordionHeaderRow: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 6,
  },
  componentAccordionTitle: {
    fontSize: 14,
  },
  componentAccordionManage: {
    '& .MuiButtonBase-root': {
      margin: 0,
    },
  },
  componentAccordionSubtitle: {
    fontSize: 16,
    marginLeft: 16,
    marginBottom: 16,
  },
  componentAccordionIcons: {
    padding: 4,
  },
  componentAccordionButton: {
    alignSelf: 'end',
  },
  loadStateTable: {
    width: '100%',
    marginBottom: 0,
    '& > div': {
      boxShadow: 'none',
    },
  },
  noComponentInlay: {
    '& > div': {
      margin: '32px 24px 8px',
    },
  },
}));

export default useStyles;
