import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { wGroupIndicesATThermal } from 'components/AgentTemplateEditView/menu/thermal';
import { IColumn } from 'components/general/types';
import { IThermalInterfaceMaterial } from 'components/general/types/thermal';
import { useActiveEntities } from 'hooks';
import guidance from './guidance';
import ThermalInterfaceMaterialDialog from './ThermalInterfaceMaterialDialog';

const thermalInterfaceMaterialsColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Resistivity',
    render: (row) => `${row.resistivity} K·m/W`,
  },
];

const ThermalInterfaceMaterialsSegment = () => {
  const { thermalInterfaceMaterials } = useActiveEntities();

  return (
    <EntityTableSegment<IThermalInterfaceMaterial>
      title="Thermal Interface Materials"
      index={wGroupIndicesATThermal.THERM_INTER_MAT}
      guidance={guidance}
      entityColumns={thermalInterfaceMaterialsColumns}
      entityData={thermalInterfaceMaterials}
      DialogComponent={ThermalInterfaceMaterialDialog}
      modelName="thermal interface material"
    ></EntityTableSegment>
  );
};

export default ThermalInterfaceMaterialsSegment;
