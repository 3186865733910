import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  decoration: {
    marginTop: 27,
    marginBottom: 70,
  },
  plot: {
    flex: '1 100%',
    overflow: 'hidden',
  },
}));

export default useStyles;
