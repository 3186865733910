import useStyles from './styles';
import LegendCircle from './LegendCircle';

export interface IData {
  label: string;
  color: string;
  gradient?: string;
}

const CustomLegend = ({ data }: { data: IData[] }) => {
  const classes = useStyles();

  return (
    <div className={classes.legendContainer}>
      {data.map((legendPoint) => {
        return (
          <div key={legendPoint.label} className={classes.legendKey}>
            <LegendCircle legendPoint={legendPoint} />
            <div>{legendPoint.label}</div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomLegend;
