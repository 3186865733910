import { useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { validatePassword } from 'utils/validators';
import { InputAdornment } from '@material-ui/core';
import LabeledInput from '.';
import * as Yup from 'yup';

export const passwordSchema = Yup.string()
  .required('All fields are required.')
  .min(8, 'Password must be at least 8 characters.')
  .max(128, 'Password must be no more than 128 characters.')
  .test(
    'passwordValid',
    'Password must contain at least 3 of the following: a lowercase letter, an uppercase letter, a special character, or a number.',
    (pword) => pword && validatePassword(pword)
    )
  

const LabeledPasswordInput = (props) => {
  const { label, ...remainingProps } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <LabeledInput
      {...remainingProps}
      label={label}
      type={showPassword ? 'text' : 'password'}
      autoComplete="new-password"
      placeholder="Password"
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            variant="outlined"
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default LabeledPasswordInput;
