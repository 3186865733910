import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  masonryGrid: (props) => ({
    display: 'flex',
    width: 'auto',
    maxWidth: props.maxWidth ? props.maxWidth : '100%',
  }),
  masonryColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

export default useStyles;
