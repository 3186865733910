import { useState } from 'react';
import * as React from 'react';
import { AccordionSummary, AccordionSummaryProps, IconButton, SvgIcon } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import EntityMenu from 'components/general/EntityMenu';
import { IGenericObject } from '../types';

interface ISharedStyledAccordionSummaryProps extends AccordionSummaryProps {
  children?: JSX.Element | null;
  iconClassName?: string;
  editable?: boolean;
  selectedData?: IGenericObject | undefined;
  onActionClick?: () => void | undefined;
  expandedClassName?: string;
}

interface IEditableProps extends ISharedStyledAccordionSummaryProps {
  editable: true;
  selectedData: IGenericObject;
  onActionClick: () => void;
}

interface INonEditableProps extends ISharedStyledAccordionSummaryProps {
  editable?: false | undefined;
  selectedData?: undefined;
  onActionClick?: undefined;
}

type TStyledAccordionSummaryProps = IEditableProps | INonEditableProps;

const StyledAccordionSummary = (props: TStyledAccordionSummaryProps) => {
  const {
    children,
    editable,
    selectedData,
    onActionClick,
    iconClassName,
    expandedClassName,
    ...remainingProps
  } = props;
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const classes = useStyles();
  const openMenu = (e: React.MouseEvent) => {
    // stopPropagation() prevents parent's onClick from being called - preventing expansion of accordion when opening menu
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <AccordionSummary
        expandIcon={
          <IconButton className={iconClassName}>
            <ExpandMoreIcon className={classes.accordionExpandIcon} />
          </IconButton>
        }
        // overwrite CSS of nested MUI accordion components
        classes={{ content: classes.accordionSummaryContent, expanded: expandedClassName }}
        {...remainingProps}
      >
        {children}
        {editable && (
          <IconButton onClick={openMenu} className={iconClassName}>
            <SvgIcon component={MoreHorizIcon} />
          </IconButton>
        )}
      </AccordionSummary>
      {editable && (
        <EntityMenu
          anchorEl={anchorEl}
          selectedData={selectedData}
          onActionClick={onActionClick}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default StyledAccordionSummary;
