import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const highLowFontSize = 11;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 170,
    width: 21,
    color: theme.palette.text.tertiary,
    alignSelf: 'stretch',
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  high: {
    fontSize: highLowFontSize,
    marginTop: 18,

    '& > svg': {
      marginLeft: 5,
    },
  },
  low: {
    fontSize: highLowFontSize,
    marginBottom: 16,

    '& > svg': {
      marginRight: 5,
    },
  },
  label: {
    fontSize: 15,
  },
  rotate: {
    transform: 'rotate(-90deg)',
    display: 'flex',
    alignItems: 'center',
  },
}));

const PriorityDecoration = (props) => {
  const { className, height } = props;
  const classes = useStyles({ height });

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.wrapper}>
        <div className={clsx(classes.high, classes.rotate)}>
          <span>Highest</span>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
        <div className={clsx(classes.label, classes.rotate)}>Priority</div>
        <div className={clsx(classes.low, classes.rotate)}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>Lowest</span>
        </div>
      </div>
    </div>
  );
};

export default PriorityDecoration;
