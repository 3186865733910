import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Badge, { BadgeProps } from '@material-ui/core/Badge';

const useStyles = makeStyles({
  root: {
    '&>span': {
      top: 'unset',
      right: 'unset',
      transform: 'none',
      transformOrigin: 'center',
      position: 'relative',
    },
  },
});

export interface IInlineBadgeProps extends BadgeProps {
  className?: string;
}

const InlineBadge = (props: IInlineBadgeProps) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return <Badge className={clsx(classes.root, className)} {...rest} />;
};

export default InlineBadge;
