import { makeStyles } from '@material-ui/core/styles';
import { nodeFieldSetLegendStyle, nodeFieldSetStyle } from '../general/sharedStyles';

interface IProps {
  title: string;
  percentage: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...nodeFieldSetStyle,
    height: '1rem',
    boxSizing: 'border-box',
    border: 'none',
    borderRadius: '1rem',
    background: ({ percentage }: { percentage: number }) => {
      percentage *= 100;
      const barColor = theme.palette.success.pastel;
      return `linear-gradient(
      to right,
      ${barColor} 0%,
      ${barColor} ${percentage}%,
      ${theme.palette.background.light} ${percentage}%,
      ${theme.palette.background.light} 100%)
    `;
    },
  },
  legend: nodeFieldSetLegendStyle,
}));

const ActuatorBar = ({ title, percentage }: IProps) => {
  const styles = useStyles({ percentage });
  return (
    <fieldset className={styles.root}>
      <legend className={styles.legend}>{title}</legend>
    </fieldset>
  );
};

export default ActuatorBar;
