import { useMemo, createContext, ReactNode, useContext } from 'react';
// import { DATA_LIMIT } from 'config';
// import { TimeContext } from './TimeProvider';
import { DataContext } from './DataProvider';
import { IGenericObject } from 'components/general/types';

interface IRangeContext {
  echartsData: IGenericObject;
}

interface IProps {
  children: ReactNode;
}

/**
 * Provides all the data for a scenario within a certain range.
 * - This data is useful for plots (time series, stats)
 * - Current range is literally [startTime, stopTime], will change in the future
 *
 * Subscribing to this context implicitly subscribes a component to:
 * - ActiveBranchContext: provides the model for the active agent
 * - DataContext: provides the data to compile for charts
 *
 * TODO:
 * - This provider handles animated vs bulk data modes, ability to view charts in a "live" fashion
 *   - Data coming in as it's produced, or at least appearing like it is
 *   - Range will become dynamic based on a time slider
 * - Will implictly subscribe to TimeContext
 */
export const RangeContext = createContext<IRangeContext>({ echartsData: {} });

const RangeProvider = ({ children }: IProps) => {
  const { seriesData } = useContext(DataContext);
  // const { time } = useContext(TimeContext);

  // TODO: For future use when animated mode vs bulk mode is a thing
  // const [liveStart, setLiveStart] = useState(0);
  // const [liveStop, setLiveStop] = useState(1);
  // const [animated, setAnimated] = useState(false);

  // TODO: When in animated mode, update start and stop time of charts
  // using a defined max chart size
  // useEffect(() => {
  //   const MAX_POINTS_PER_CHART = DATA_LIMIT;
  // Note this now returns -1 if not found, else index of closest value (rounding down)
  // And timestamps was removed - talk to Bas
  //   const index = binarySearch(timestamps, time);
  //   const start = Math.max(0, index - MAX_POINTS_PER_CHART);
  //   setLiveStart(start);
  //   setLiveStop(start === 0 ? index : start + MAX_POINTS_PER_CHART);
  // }, [time]);

  const echartsData = useMemo(() => {
    // TODO: Incorporate above liveStart and liveStop time into this compilation.
    // It would probably be best to compile echartsData from seriesData, memoize,
    // and query by liveStart and liveStop after.

    // To use keyed column format, nested keys are prohibited by echarts so flatten
    const data: { [key: string]: [] } = {};
    for (const streamId in seriesData.columnar) {
      for (const key in seriesData.columnar[streamId]) {
        data[`${streamId}.${key}`] = seriesData.columnar[streamId][key];
      }
    }
    return data;
  }, [seriesData]);

  // TODO: full sim stats

  const value = useMemo(
    () => ({
      echartsData,
    }),
    [echartsData]
  );

  return <RangeContext.Provider value={value}>{children}</RangeContext.Provider>;
};

export default RangeProvider;
