/* eslint no-template-curly-in-string: 0 */
import * as Yup from 'yup';

const validation = Yup.object().shape({
  mass: Yup.number().required('Spacecraft mass is required.').moreThan(0, 'Mass must be positive.'),
  inertia: Yup.array()
    .of(
      Yup.array()
        .of(Yup.number().required('Complete inertia matrix is required.'))
        .length(3, 'Complete inertia matrix is required.')
    )
    .length(3, 'Complete inertia matrix is required.'),
});

export default validation;
