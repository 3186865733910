import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/Edit';
import useStyles from './styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';

const Accent = (props) => {
  const { children, icon, header, className, onAddAction, onEditAction } = props;

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.root, className)}>
        <p className={classes.header}>
          {icon}
          {header}
          <span className={classes.actions}>
            {onAddAction && (
              <IconButton onClick={onAddAction}>
                <AddOutlinedIcon />
              </IconButton>
            )}
            {onEditAction && (
              <IconButton onClick={onEditAction}>
                <EditIcon />
              </IconButton>
            )}
          </span>
        </p>
        {children}
      </div>
    </div>
  );
};

export default Accent;
