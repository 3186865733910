import { makeGuidance } from 'hooks';

const ivCurveHeader = "Define the four characteristic points of the solar cell's IV curve.";

const maxPowerPoint = {
  subHeading: 'Maximum Power Point',
  chunk:
    'The maximum power point (MPP) is the point at which the solar cell yields the highest power. This point is characterized by the maximum power voltage (Vmp) and the maximum power current (Imp).',
};

export const solarCellGuidance = {
  _default: {
    heading: 'Solar Cells',
    body: [
      {
        chunk:
          'Solar cells are defined using their IV (current-voltage) curve, which is characterized using the open circuit voltage (Voc), short circuit current (Isc), and maximum power points (Vmp and Imp). The solar cell equivalent circuit parameters will scale with the number of junctions.',
      },
    ],
  },

  openCircuitVoltage: {
    heading: 'Solar Cell IV Curve',
    body: [
      {
        chunk: ivCurveHeader,
      },
      {
        subHeading: 'Open Circuit Voltage',
        chunk:
          'The open circuit voltage (Voc) is the maximum voltage at which the solar cell can operate and is the voltage of the cell when its current is zero.',
      },
    ],
  },

  shortCircuitCurrent: {
    heading: 'Solar Cell IV Curve',
    body: [
      {
        chunk: ivCurveHeader,
      },
      {
        subHeading: 'Short Circuit Current',
        chunk:
          'The short circuit current (Isc) is the largest current which can be drawn from the cell.',
      },
    ],
  },

  maxPowerVoltage: {
    heading: 'Solar Cell IV Curve',
    body: [
      {
        chunk: ivCurveHeader,
      },
      maxPowerPoint,
    ],
  },

  maxPowerCurrent: {
    heading: 'Solar Cell IV Curve',
    body: [
      {
        chunk: ivCurveHeader,
      },
      maxPowerPoint,
    ],
  },

  numJunctions: {
    heading: 'Number of Junctions',
    body: [
      {
        chunk:
          "A multi-junction solar cell is modeled as mutiple wavelength-optimized solar cells connected in series. The number of p-n junctions of the solar cell scale the parameters of the equivalent circuit characterized by the cell's IV curve. Please consult your cell's datasheet for its number of junctions if unknown.",
      },
    ],
  },
};

const useGuidance = makeGuidance(solarCellGuidance);
export default useGuidance;
