import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 2,
  },
  root: {
    borderLeft: '2px solid ' + theme.palette.background.lightest,
    paddingLeft: 8,
  },
  header: {
    color: theme.palette.info.main,
    margin: '8px 0px 0px 0px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 0,
    // Height is needed to specify a uniform height for all accents (helps if some have EditIcon and others do not)
    height: 25,

    '& > svg': {
      marginRight: 3,
    },
    '& svg': {
      height: 15,
      width: 'auto',
    },
  },
  actions: {
    marginRight: 0,
    marginLeft: 'auto',

    '& button': {
      color: theme.palette.info.main,
      padding: 3,

      '& svg': {
        height: 20,
        width: 'auto',
      },
    },
  },
}));

export default useStyles;
