import { ResponsiveSankey } from '@nivo/sankey';
import { ErrorBoundary } from 'react-error-boundary';
import theme from 'theme';
import ViewPortInlay from '../ViewPortInlay';

const FallbackComponent = () => {
  return <ViewPortInlay text="An error occurred while rendering" />;
};
const handleError = (error) => console.error(error);

const SankeyDiagram = (props) => {
  const { data, colors, labels, linkTooltip, nodeTooltip } = props;
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={handleError}>
      <ResponsiveSankey
        data={data}
        margin={{ top: 40, right: 20, bottom: 40, left: 20 }}
        align="center"
        sort="input"
        // Must use a colors selector in order to have colors line up by column
        // Default by Nivo is to assign them alternating based on index
        colors={colors}
        nodeOpacity={1}
        nodeHoverOthersOpacity={1}
        nodeThickness={16}
        nodeSpacing={20}
        nodeBorderWidth={2}
        nodeBorderColor={{ from: 'color' }}
        nodeBorderRadius={1}
        linkOpacity={0.3}
        linkHoverOthersOpacity={0.3}
        linkContract={0}
        enableLinkGradient={true}
        theme={{
          fontSize: 12,
          tooltip: {
            container: {
              backgroundColor: theme.palette.background.light,
              borderStyle: 'none',
              boxShadow: theme.shadows[5],
            },
          },
        }}
        // Truncate labels if the nodes are on the end so they will not be cutoff
        label={labels}
        labelPosition="inside"
        labelOrientation="horizontal"
        labelPadding={5}
        labelTextColor={theme.palette.primary.contrastText}
        animate={false}
        linkTooltip={linkTooltip}
        nodeTooltip={nodeTooltip}
      />
    </ErrorBoundary>
  );
};
export default SankeyDiagram;
