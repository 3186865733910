import { DependencyList, EffectCallback, useEffect } from 'react';
import useMountStatus from './useMountStatus';

/**
 * Runs a useEffect only once a component is unmounted. Does not support cleanup functions.
 *
 * @param effect a callback function that runs the desired effect
 * @param deps a dependency list for the given callback
 */
const useUnmountEffect = (effect: EffectCallback, deps: DependencyList) => {
  const isMounted = useMountStatus();

  useEffect(() => {
    return () => {
      if (!isMounted()) effect();
    };
  }, [deps, effect, isMounted]);
};

export default useUnmountEffect;
