import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    height: '100%',
    ...theme.typography.body,
    color: theme.palette.background.contrastText,
    backgroundColor: theme.palette.background.dark,
    ...theme.mixins.noScroll,
    position: 'relative',
  },
  content: (props) => ({
    ...theme.mixins.boardElementStyles,
    minWidth: props.minWidth || 0,
  }),
}));

export default useStyles;
