import { ReactNode } from 'react';
import Accent from 'components/general/Accent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThermometerHalf } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  children: ReactNode;
  header: string;
  icon?: ReactNode;
  className?: string;
  onAddAction?: () => void;
  onEditAction?: () => void;
}

const ThermalAccent = (props: IProps) => {
  const { children, ...remainingProps } = props;

  return (
    <Accent icon={<FontAwesomeIcon icon={faThermometerHalf} />} {...remainingProps}>
      {children}
    </Accent>
  );
};

export default ThermalAccent;
