import { useMemo } from 'react';
import WizardSegment, { IWizardSegmentProps, IGridConfig } from './WizardSegment';
import Grid from '@material-ui/core/Grid';
import GuidanceCard from '../GuidanceCard';
import { IGuidanceCard } from '../types';
import useStyles from './WizardSegment/styles';

interface IProps extends IWizardSegmentProps {
  guidance: IGuidanceCard;
}

const defaultGridConfig: IGridConfig = { left: { xs: 12, md: 6 }, right: { xs: 12, md: 6 } };

const EntitySegment = (props: IProps) => {
  const classes = useStyles();

  const { children, guidance, gridConfig, ...remainingProps } = props;
  const config = useMemo(() => gridConfig ?? defaultGridConfig, [gridConfig]);

  return (
    <WizardSegment {...remainingProps}>
      <Grid container spacing={2}>
        <Grid item xs={config.left.xs} md={config.left.md} className={classes.swapRight}>
          {children}
        </Grid>
        <Grid item xs={config.right.xs} md={config.right.md} className={classes.swapLeft}>
          <GuidanceCard guidance={guidance} />
        </Grid>
      </Grid>
    </WizardSegment>
  );
};

export default EntitySegment;
