import { useState } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import * as Yup from 'yup';
import { useFormikForm, useUser, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useDispatch } from 'react-redux';

const defaultValues = { currentPassword: '' };

const passwordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Enter your existing password.'),
});

const AuthDialog = (props) => {
  const { dialogOpen, onSuccess, onClose } = props;
  const user = useUser();
  const {
    User: {
      actions: { login },
    },
  } = SatelliteApi;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const authenticate = (values) => {
    setLoading(true);
    dispatch(
      login({
        email: user.email,
        password: values.currentPassword,
        successCallback: () => {
          setFieldValue('currentPassword', '');
          setLoading(false);
          onSuccess();
        },
        failureCallback: () => {
          // authentication failure callback
          setFieldValue('currentPassword', '');
          enqueueSnackbar('Incorrect password.');
          setLoading(false);
        },
      })
    );
  };
  const { formik } = useFormikForm(defaultValues, authenticate, passwordSchema, defaultValues);
  const { handleSubmit, setFieldValue, getFieldProps } = formik;

  return (
    <Dialog
      prompt="Confirm changes"
      open={dialogOpen}
      onSubmit={handleSubmit}
      onClose={() => {
        setFieldValue('currentPassword', '');
        onClose();
      }}
      loading={loading}
    >
      <p>Please enter your current password to confirm your changes.</p>
      <LabeledInput
        {...getFieldProps('currentPassword')}
        type="password"
        label="Password"
        placeholder="Current Password"
        autoFocus
      />
    </Dialog>
  );
};

export default AuthDialog;
