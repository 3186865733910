import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  solarArrayGroup: {
    display: 'flex',
    marginBottom: '1rem',
  },
  solarArrayAccordion: {
    marginRight: '1rem',
    minWidth: '70%',
    '&.Mui-expanded': {
      marginRight: '1rem !important',
      marginBottom: '0px !important',
      minWidth: '70% !important',
    },
    borderRadius: 3,
    [theme.breakpoints.up('lg')]: {
      marginRight: 0,
      '&.Mui-expanded': {
        marginRight: '0px !important',
      },
    },
  },
  solarArrayAccordionSummary: {
    alignItems: 'center',
    '&.Mui-expanded': {
      minHeight: 40,
    },
    minHeight: 40,
    justifyContent: 'space-between',
  },
  solarArrayAccordionContent: {
    margin: 0,
    display: 'inline-block',
    width: '80%',
    flexGrow: 0,
    '&.Mui-expanded': { margin: '0px !important' },
  },
  solarArray: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    paddingBottom: '.5rem',
    flexDirection: 'column',
  },
  solarArrayExpand: {
    padding: '0 .25em',
  },
  solarArrayName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '.8rem',
  },
}));

export default useStyles;
