import { useState } from 'react';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import EntityMenu from 'components/general/EntityMenu';
import { ISubsystem } from 'components/general/types/power';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { SubsystemVables } from 'utils/vable';
import EditNameForm from './EditNameForm';
import DeleteEntityDialog from 'components/general/dialogs/DeleteEntityDialog';
import { useInReadOnlyBranch } from 'hooks';

interface IProps {
  subsystem: ISubsystem;
  preventTabOnAddCustomButton: () => void;
}

const SubsystemTitle = ({ subsystem, preventTabOnAddCustomButton }: IProps) => {
  const { Subsystem } = SatelliteApi;
  const inReadOnlyBranch = useInReadOnlyBranch();

  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [editSelected, setEditSelected] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(false);

  const onActionClick = (_subsystem: ISubsystem, action: string) => {
    if (action === 'delete') setDeleteSelected(true);
    if (action === 'edit') setEditSelected(true);
  };

  const isCustom = subsystem.category === SubsystemVables.Categories.CUSTOM.value;
  const ssName = isCustom ? subsystem.name : SubsystemVables.Categories[subsystem.category].label;

  if (!isCustom || inReadOnlyBranch) return <>{ssName}</>;

  if (editSelected) return <EditNameForm subsystem={subsystem} setEditSelected={setEditSelected} />;

  return (
    <>
      {ssName}{' '}
      <IconButton
        onClick={(e) => setAnchorEl(e.target)}
        edge="end"
        size="small"
        style={{ position: 'absolute', transform: 'translate(15%, -25%)' }}
      >
        <MoreHorizIcon />
      </IconButton>
      <EntityMenu
        anchorEl={anchorEl}
        selectedData={subsystem}
        onActionClick={onActionClick}
        handleClose={() => setAnchorEl(null)}
      />
      <DeleteEntityDialog
        action={Subsystem.actions.deleteSubsystem}
        entity={subsystem}
        entityTypeText={'Subsystem'}
        open={deleteSelected}
        onClose={() => setDeleteSelected(false)}
        onSuccess={() => preventTabOnAddCustomButton()}
      />
    </>
  );
};

export default SubsystemTitle;
