import MomentUtils from '@date-io/moment';

class UTCUtils extends MomentUtils {
  parse(value, format) {
    if (value === '') {
      return null;
    }

    if (this.locale) {
      return this.moment.utc(value, format, this.locale, true);
    }

    return this.moment.utc(value, format, true);
  }
}

export default UTCUtils;
