import WGroup from 'components/general/WGroup';
import ConOpsTimeSeriesWidget from './ConOpsTimeSeriesWidget';

export default function ConOpsTimeSeriesWGroup(props) {
  const { index } = props;

  return (
    <WGroup index={index}>
      <ConOpsTimeSeriesWidget />
    </WGroup>
  );
}
