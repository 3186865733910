import Wizard from 'components/general/wizards/Wizard';
import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import PowerProcessorSegment from './PowerProcessorSegment';
import BusRegulatorsSegment from './BusRegulatorsSegment';
import { useActiveEntities } from 'hooks';
import COTSDialogButton from 'components/general/COTSDialogButton';
import { ISubsystem } from 'components/general/types/power';

const PowerProcessingWizard = () => {
  const { subsystems } = useActiveEntities();
  const powerSubsystem = subsystems.find((s) => s.category === 'POWER');
  return (
    <Wizard>
      <COTSDialogButton entityKey="powerProcessor" subsystem={powerSubsystem as ISubsystem} />
      <PowerProcessorSegment index={wGroupIndicesATPower.POWER_PP_PC} />
      <BusRegulatorsSegment index={wGroupIndicesATPower.POWER_PP_BR} />
    </Wizard>
  );
};

export default PowerProcessingWizard;
