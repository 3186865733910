import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  active: boolean;
}

const useStyles = makeStyles((theme) => ({
  subsystemContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1rem',
    marginBottom: '.5em',
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  subsystem: (props: IProps) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: props.active ? 'rgb(84, 143, 89)' : theme.palette.background.paper,
    color: 'white',
    width: '70%',
    overflow: 'hidden',
    padding: '.5rem',
    borderRadius: 3,
    boxShadow: theme.shadows[5],
    marginLeft: '.8rem',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
    boxSizing: 'border-box',
  }),
  subsystemIcon: {
    color: theme.palette.text.primary,
    opacity: 0.8,
    marginRight: '.25rem',
    fontSize: '2em',
  },
  subsystemName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '.8rem',
  },
}));

export default useStyles;
