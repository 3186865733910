import { makeGuidance } from 'hooks';
import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';

const solarCellConfiguration = {
  subHeading: 'Solar Cell Configuration',
  chunk:
    'Select the number of solar cells connected in series (a "string") and the number of series string connected in parallel.',
};

const solarCellTechnology = {
  heading: 'Select the Solar Cell',
  body: [
    {
      chunk:
        'Select the solar cell and enter its series and parallel configuration within the panel.',
    },
    solarCellConfiguration,
  ],
};

export const solarPanelGuidance = {
  _default: {
    heading: 'Solar Panels',
    body: [
      {
        chunk:
          'Solar panels are assembled using one or more identical solar cells and can be mounted on a sun-tracking or fixed external surface.',
      },
    ],
  },

  surface: {
    heading: 'Mount the Solar Panel',
    body: [
      {
        chunk:
          "Select the satellite's external surface on which the solar panel is mounted or create a new surface by clicking on the '+' button.",
      },
      {
        subHeading: 'Fixed Panels',
        chunk:
          "If mounted on a fixed surface, the solar panel will be fixed to the satellite's local frame of reference. Its pointing will depend on your mission's ConOps and pointing logic.",
      },
      {
        subHeading: 'Sun-Tracking Panels',
        chunk:
          'If mounted on a sun-tracking surface, the solar panel will maximize its alignment with the Sun throughout the mission, maximizing the available power at each time-step.',
      },
    ],
  },

  cell: solarCellTechnology,

  numSeries: solarCellTechnology,

  numParallel: solarCellTechnology,

  blockingDiodeDrop: {
    heading: 'Blocking Diode',
    body: [
      {
        chunk:
          'The blocking diode prevents the battery and/or other panels from discharging back through a given panel.',
      },
    ],
  },
  ...componentThermalGuidance,
};

const useGuidance = makeGuidance(solarPanelGuidance);
export default useGuidance;
