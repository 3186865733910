import { makeStyles } from '@material-ui/core/styles';
import { IProps } from './index';

const useStyles = makeStyles((theme) => ({
  arrowContainer: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  arrow: (props: IProps) => ({
    fontSize: 60,
    height: '1em',
    color:
      (props.fill && (props.type === 'primary' ? `rgb(238, 216, 72)` : `rgba(125, 92, 217)`)) ||
      theme.palette.background.inlayText,
    transform: props.flipped ? 'scale(-1, 1)' : 'scale(1)',
    [theme.breakpoints.up('lg')]: {
      fontSize: 40,
    },
  }),
}));

export default useStyles;
