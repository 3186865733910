import { useCallback } from 'react';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from './styles';
import { IForm } from '../index';
import StyledButton from 'components/general/StyledButton';
import { IGenericObject } from 'components/general/types';

interface IProps {
  formik: {
    getFieldProps: (name: string) => IGenericObject;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValues: (f: any) => void;
    values: { curve: IGenericObject };
  };
}

const CurvePointInputs = (props: IProps) => {
  const {
    formik: {
      getFieldProps,
      setValues,
      values: { curve },
    },
  } = props;
  const classes = useStyles();

  const addPoint = useCallback(() => {
    setValues((prev: IForm) => ({
      ...prev,
      curve: {
        pointsSoc: [...prev.curve.pointsSoc, ''],
        pointsVoc: [...prev.curve.pointsVoc, ''],
      },
    }));
  }, [setValues]);

  const deletePoint = useCallback(
    (index: number) => {
      setValues((prev: IForm) => ({
        ...prev,
        curve: {
          pointsSoc: prev.curve.pointsSoc.filter((_ps, i) => i !== index),
          pointsVoc: prev.curve.pointsVoc.filter((_po, i) => i !== index),
        },
      }));
    },
    [setValues]
  );
  return (
    <>
      {curve.pointsSoc.map((_p: number | '', i: number) => (
        <div className={classes.pointInputRow} key={i}>
          <div className={classes.pointInnerInputRow}>
            <LabeledInput
              label={i === 0 && 'SoC'}
              type="number"
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              {...getFieldProps('curve.pointsSoc.' + i)}
            />
            <LabeledInput
              label={i === 0 && 'Voc'}
              type="number"
              endAdornment={<InputAdornment position="end">V</InputAdornment>}
              {...getFieldProps('curve.pointsVoc.' + i)}
            />
          </div>
          <IconButton
            className={classes.deleteIcon}
            onClick={() => {
              deletePoint(i);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <StyledButton onClick={addPoint} fullWidth addIcon>
        Add point to curve
      </StyledButton>
    </>
  );
};

export default CurvePointInputs;
