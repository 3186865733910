import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    '& tr': {
      lineHeight: '18px',
    },
    '& td:first-child': {
      paddingRight: 10,
    },
  },
}));

const StyledBasicTable = (props) => {
  const { className, children } = props;
  const classes = useStyles();

  return <table className={clsx(classes.root, className)}>{children}</table>;
};

export default StyledBasicTable;
