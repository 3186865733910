import { useContext } from 'react';
import { faSatellite } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpacecraftContext } from 'providers';
import A from 'components/general/A';
import useStyles from './styles';
import { ISpacecraftDialogConfig } from 'components/general/types';

const SpacecraftDialogInlayButton = (props: { dialogTabNumber: number }) => {
  const { dialogTabNumber } = props;
  const { setSpacecraftDialogConfig } = useContext(SpacecraftContext);
  const classes = useStyles();
  return (
    <span
      className={classes.spacecraftDialogInlayButton}
      onClick={() => {
        setSpacecraftDialogConfig((prev: ISpacecraftDialogConfig) => ({
          ...prev,
          open: true,
          tabNumber: dialogTabNumber,
        }));
      }}
    >
      <FontAwesomeIcon icon={faSatellite} />
      <A>Spacecraft Dialog</A>
    </span>
  );
};

export default SpacecraftDialogInlayButton;
