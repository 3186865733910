import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 5,
  },
}));

export default useStyles;
