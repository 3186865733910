import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    width: '100%',
    marginBottom: 10,
  },
  prompt: {
    textAlign: 'left',
    marginBottom: 10,
    marginTop: 0,
  },
}));

export default useStyles;
