import Accent from 'components/general/Accent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSatellite } from '@fortawesome/free-solid-svg-icons';

const SpacecraftAccent = (props) => {
  const { children, ...remainingProps } = props;

  return (
    <Accent icon={<FontAwesomeIcon icon={faSatellite} />} {...remainingProps}>
      {children}
    </Accent>
  );
};

export default SpacecraftAccent;
