export const getFirstError = (errors) => {
  let keys = Object.keys(errors);
  // In the case of an array formik returns an array of strings so we must test if errors itself is an array first
  if (Array.isArray(errors)) {
    // When validating array of fields, each field without an error will return as an array element of undefined. This loop skips those fields to return the first defined error
    for (const e of errors) {
      if (e) return e;
    }
  } else if (typeof errors[0] === 'string') {
    return errors;
  } else if (keys.length > 0) {
    return getFirstError(errors[keys[0]]);
  } else {
    return undefined;
  }
};
