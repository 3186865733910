import theme from 'theme';

const SELECT_HEIGHT = theme.dimensions.inputs.height - 2;

const styles = {
  container: (provided, state) => ({
    ...provided,
    borderColor: state.selectProps.error
      ? theme.palette.error.main
      : theme.palette.background.contrastText,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    marginBottom: state.selectProps.noMargin ? 0 : theme.dimensions.inputs.marginBottom,
    backgroundColor: 'transparent',
    borderColor: state.selectProps.error
      ? theme.palette.error.main
      : theme.palette.background.contrastText,
    color: theme.palette.background.contrastText,
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: theme.dimensions.inputs.borderRadius,
    minHeight: SELECT_HEIGHT,
    ...theme.typography.highlight1,

    '&:hover': {
      borderColor: 'inherit',
    },
  }),
  input: (provided, state) => ({
    ...provided,
    height: SELECT_HEIGHT,
    color: theme.palette.background.contrastText,
    padding: 0,
    margin: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.selectProps.isDisabled
      ? theme.palette.action.disabled
      : theme.palette.background.contrastText,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: SELECT_HEIGHT,
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.background.contrastText,
    boxShadow: theme.shadows[8],
    zIndex: 9999,
  }),
  option: (provided, state) => {
    let backgroundColor;
    let color = theme.palette.background.contrastText;
    if (state.isDisabled) {
      backgroundColor = theme.palette.background.default;
      color = theme.palette.text.secondary;
    } else if (state.isSelected) {
      backgroundColor = theme.palette.action.selected;
    } else if (state.isFocused) {
      backgroundColor = theme.palette.action.hover;
    } else {
      backgroundColor = 'transparent';
    }

    return {
      ...provided,
      backgroundColor,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      color,

      '&:active': {
        backgroundColor: theme.palette.action.active,
      },
    };
  },
};

export default styles;
