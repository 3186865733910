import { useMemo } from 'react';
import { useActiveEntities } from 'hooks';
import Wizard from 'components/general/wizards/Wizard';
import { ISubsystem } from 'components/general/types/power';
import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import LoadsSegment from './LoadsSegment';
import useStyles from './styles';
import SpacecraftDialogInlay from 'components/general/SpacecraftDialogInlay';
import { ssTabsBeginIdx } from 'components/general/SpacecraftDialog';

const SubsystemLoadingWizard = () => {
  const { subsystems } = useActiveEntities();
  const classes = useStyles();

  const tabNumberMap = useMemo(() => {
    return subsystems.reduce((acc: { [key: string]: number }, s: ISubsystem, i: number) => {
      acc[s.name] = i + ssTabsBeginIdx;
      return acc;
    }, {});
  }, [subsystems]);

  return (
    <div className={classes.root}>
      <Wizard>
        {subsystems.map((s: ISubsystem) => (
          <LoadsSegment
            index={wGroupIndicesATPower.POWER_SSL.replaceAll('*', '') + `.${s.id}`}
            key={s.name}
            subsystem={s}
            onActionClick={() => undefined}
            dialogTabNumber={tabNumberMap[s.name]}
          />
        ))}
      </Wizard>
      <SpacecraftDialogInlay
        className={classes.subsystemHelperInlay}
        dialogTabNumber={ssTabsBeginIdx}
        text="Add and manage subsystems in the spacecraftDialog"
      />
    </div>
  );
};
export default SubsystemLoadingWizard;
