import useStyles from './styles';

const Wizard = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.spacer}>{children}</div>
    </div>
  );
};

export default Wizard;
