import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import WGroup from 'components/general/WGroup';
import PowerGenerationWizard from './PowerGenerationWizard';

const PowerGenerationEditBoard = () => {
  return (
    <WGroup index={wGroupIndicesATPower.POWER_PG}>
      <PowerGenerationWizard />
    </WGroup>
  );
};

export default PowerGenerationEditBoard;
