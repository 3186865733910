import useStyles from './styles';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PowerProcessor = () => {
  const classes = useStyles();
  return (
    <div className={classes.powerProcessorContainer}>
      <div className={classes.roundedCornersContainer}>
        <div className={classes.innerContainer}>
          <div className={classes.iconContainer}>
            <FontAwesomeIcon icon={faBolt} />
          </div>
        </div>
        <div className={classes.xOverlayContainer}>
          <div className={classes.xOverlay}></div>
        </div>
        <div className={classes.yOverlayContainer}>
          <div className={classes.yOverlay}></div>
        </div>
      </div>
    </div>
  );
};

export default PowerProcessor;
