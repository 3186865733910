import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 220,
  },
  linkPreview: {
    backgroundColor: theme.palette.background.paper,
    padding: 10,
    borderRadius: 3,
    boxShadow: theme.shadows[3],
  },
  linkTitle: {
    color: theme.palette.primary.light,
    margin: 0,
  },
  linkText: ({ noClick }: { noClick: boolean }) => ({
    cursor: noClick ? 'not-allowed' : 'copy',
    userSelect: 'none',
    margin: 0,
    wordBreak: 'break-all',
    transition: 'color 0.2s ease-in',
    '&:active': {
      color: noClick ? 'auto' : theme.palette.text.tertiary,
      transition: 'color 0s',
    },
  }),
}));

export default useStyles;
