import styled from 'styled-components';

const A = styled('a')`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default A;
