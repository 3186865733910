import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  legendContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    // [theme.breakpoints.down('md')]: {
    //   width: '100%',
    // },
  },
  legendKey: {
    display: 'flex',
    fontSize: 14,
    alignItems: 'center',
  },
}));

export default useStyles;
