import WGroup from 'components/general/WGroup';
import Wizard from 'components/general/wizards/Wizard';
import { wGroupIndicesATThermal } from 'components/AgentTemplateEditView/menu/thermal';

import TempControllersSegment from './TempControllersSegment';
import ThermalInterfaceMaterialsSegment from './ThermalInterfaceMaterialsSegment';
import ThermalInterfacesSegment from './ThermalInterfacesSegment';

const ThermalEditBoard = () => {
  const masonryConfig = { mdLg: [50, 50] };
  return (
    <WGroup index={wGroupIndicesATThermal.THERMAL} masonryConfig={masonryConfig}>
      <Wizard>
        <TempControllersSegment />
        <ThermalInterfaceMaterialsSegment />
        <ThermalInterfacesSegment />
      </Wizard>
    </WGroup>
  );
};

export default ThermalEditBoard;
