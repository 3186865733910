import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  accordionSummaryContent: {
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.Mui-expanded': { margin: 0 },
  },
  accordionExpandIcon: {
    padding: 0,
  },
}));

export default useStyles;
