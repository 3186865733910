import { makeGuidance } from 'hooks';
import genericThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/genericThermalGuidance';
const energyUseBody = [
  {
    subHeading: 'Solar Absorptivity',
    chunk: ' Percent of energy radiated from the sun onto the surface that is trapped as heat.',
  },
  {
    subHeading: 'Diffuse Solar Reflectivity',
    chunk:
      'Percent of light diffusely reflected from the surface, used for solar radiation pressure calculations. Diffuse refers to light that is scattered on impact.',
    //TODO, add DRAWING
    // todo
    // graphic: singleConvMPPTTopologyReducedGraphic,
    // maxHeight: 100,
    // import singleConvHybridTopologyReducedGraphic from 'multimedia/guidance/single_conv_hybrid_topology_reduced.png';
  },
  {
    subHeading: 'Specular Solar Reflectivity',
    chunk:
      'Percent of light specularly reflected from the surface, used for solar radiation pressure calculations. Specular refers to light that is bounced on impact.',
    //TODO, add DRAWING
  },
  {
    chunk:
      'Solar Absorptivity + Specular Reflectivity + Diffuse Reflectivity must be less than or equal to 100%. ',
  },
];
const useGuidance = makeGuidance({
  _default: {
    heading: 'Surface Material',
    body: 'Define the energy absorption/dissipation properties of this material.',
  },
  irEmissivity: {
    heading: 'IR Emissivity',
    body: [
      {
        chunk:
          'Percent of thermal energy radiated at IR wavelengths from this surface relative to a black body of the same temperature.',
      },
    ],
  },
  solarAbsorptivity: {
    heading: 'Solar Energy Absorption/Reflection',
    body: energyUseBody,
  },
  diffuseSolarReflectivity: {
    heading: 'Solar Energy Absorption/Reflection',
    body: energyUseBody,
  },
  specularSolarReflectivity: {
    heading: 'Solar Energy Absorption/Reflection',
    body: energyUseBody,
  },
  ...genericThermalGuidance,
});
export default useGuidance;
