import { deg2Rad } from 'utils/math';

export const enuOffsetHeading = deg2Rad(90);
export const enuOffsetPitch = deg2Rad(0);
export const enuOffsetRoll = deg2Rad(0);
export const cesiumModelScaleDown = 0.7; // Used to reduce model size in altitudes lower than 1000 km to prevent the model from touching the Earth
export const cesiumScaleAltitude = 1000;
export const cesiumScaleLinearSlope = 33;
export const cesiumScaleLinearIntercept = 766666;
export const cesiumBfVectorScaleDown = cesiumModelScaleDown + 0.1; // Used to reduce bfVector size in altitudes lower than 1000 km to prevent the vectors from touching the Earth
export const cesiumBfVectorScaleUp = 1.2; // Used to increase bfVector size to ensure the arrows stick out of the model
export const cesiumBfVectorLabelOffsetScale = 1.2;
export const perigeeAltScaleCap = 1000;

export const cesiumClockMultiplierScaleDefault = 20;
