import { useState, useEffect, memo } from 'react';
import { AccordionDetails } from '@material-ui/core';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { IComponent, ILoadState } from 'components/general/types/power';
import { NestedStyledAccordion } from 'components/general/StyledAccordion';
import StyledAccordionSummary from 'components/general/StyledAccordionSummary';
import useStyles from './styles';
import StyledDivider from 'components/general/StyledDivider';
import { useActiveEntities, useEntityDialogControl } from 'hooks';
import LoadStateDialog from './LoadStateDialog';
import { TDialogConfig } from 'components/general/types';

const componentColumns = [
  { title: 'Load States', field: 'name' },
  { title: 'Number of loads', render: (rowData: ILoadState) => rowData.loads.length },
];

interface IProps {
  component: IComponent;
}
const ComponentAccordion = (props: IProps) => {
  const { component } = props;
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const { model } = useActiveEntities();
  const loadStates = component.loadStates;

  const loadDialogControl = useEntityDialogControl<ILoadState>();
  const { dialogConfig, setDialogConfig, openDialogForNew, openDialogForExisting } =
    loadDialogControl;
  const { open } = dialogConfig;

  // This useEffect re-opens the dialog for the same load state after edit/create/delete of a load => rerenders dialog,
  // otherwise the new load does not show up in the list of loads
  useEffect(() => {
    setDialogConfig((prev: TDialogConfig<ILoadState>) => {
      if (prev.open && prev.entity && prev.action === 'edit')
        return {
          open: true,
          action: 'edit',
          entity: model.LoadState.byId(prev.entity.id),
        };
      return prev;
    });
  }, [loadStates, model, setDialogConfig]);

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <NestedStyledAccordion
      expanded={expanded}
      onChange={handleChange}
      classes={{ root: classes.componentAccordion, expanded: classes.componentAccordionExpanded }}
    >
      <StyledAccordionSummary iconClassName={classes.componentAccordionIcons}>
        <h3 className={classes.componentAccordionTitle}>{component.name}</h3>
      </StyledAccordionSummary>
      {expanded && (
        <AccordionDetails>
          <StyledDivider className={classes.componentAccordionDivider} />
          <WidgetTable
            columns={componentColumns}
            data={loadStates}
            onActionClick={openDialogForExisting}
            onFabClick={openDialogForNew}
            modelName="load state"
            className={classes.loadStateTable}
          />
        </AccordionDetails>
      )}
      {/* Only load the load state dialog component if open is true, otherwise every dialog will load upon rendering the route */}
      {open && <LoadStateDialog component={component} control={loadDialogControl} />}
    </NestedStyledAccordion>
  );
};

export default memo(ComponentAccordion);
