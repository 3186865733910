import { makeGuidance } from 'hooks';
import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';

const tempControllerPermanentGuidance =
  'Configure Heater/Cooler with a controlled Component for the Temperature Controller to regulate and the type of Temperature Controller.';

export const useGuidance = makeGuidance({
  _default: {
    heading: 'Temperature Controllers',
    body: [
      {
        chunk: tempControllerPermanentGuidance,
      },
      {
        subHeading: 'Heater',
        chunk:
          'Specify the heating rate in Watts that the heater will apply to the controlled Component.',
      },
      {
        subHeading: 'Cooler',
        chunk:
          'Coolers have a cooling rate in Watts, and an efficiency that determines how much heat they create when cooling the controlled Component. Coolers sink the regulated heat into another Component, which is specified in the Thermal Interface diagram.',
      },
    ],
  },

  ...componentThermalGuidance,
});

const tempControllersSegmentGuidance = {
  heading: 'Temperature Controllers',
  body: [
    {
      chunk:
        'Temperature Controllers are Heaters and Coolers that can be configured for your spacecraft. These devices are Components that are assigned to regulate the temperature of their assigned Component. Target temperatures are managed by Control States linked to Operational Modes. During an active state Temperature Controllers will be active only when the temperature goal is not met. For Coolers this means the regulated interface will receive full cooling until the temperature is at or below commanded. For Heaters this means the regulated interface will receive full heating until the temperature is at or above the commanded.',
    },
    {
      chunk:
        'Controllers have a single controlled Component but do not need to be connected directly to that Component. Indirect control by heating/cooling nearby structures according to the controlled Component state is supported.',
    },
  ],
};

export default tempControllersSegmentGuidance;
