import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  More: forwardRef((props, ref) => <MoreHorizIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

TableIcons.displayName = 'TableIcons';
TableIcons.Add.displayName = 'TableIcons.Add';
TableIcons.Check.displayName = 'TableIcons.Check';
TableIcons.Clear.displayName = 'TableIcons.Clear';
TableIcons.Delete.displayName = 'TableIcons.Delete';
TableIcons.DetailPanel.displayName = 'TableIcons.DetailPanel';
TableIcons.Edit.displayName = 'TableIcons.Edit';
TableIcons.Export.displayName = 'TableIcons.Export';
TableIcons.Filter.displayName = 'TableIcons.Filter';
TableIcons.FirstPage.displayName = 'TableIcons.FirstPage';
TableIcons.LastPage.displayName = 'TableIcons.LastPage';
TableIcons.More.displayName = 'TableIcons.More';
TableIcons.NextPage.displayName = 'TableIcons.NextPage';
TableIcons.PreviousPage.displayName = 'TableIcons.PreviousPage';
TableIcons.ResetSearch.displayName = 'TableIcons.ResetSearch';
TableIcons.Search.displayName = 'TableIcons.Search';
TableIcons.SortArrow.displayName = 'TableIcons.SortArrow';
TableIcons.ThirdStateCheck.displayName = 'TableIcons.ThirdStateCheck';
TableIcons.ViewColumn.displayName = 'TableIcons.ViewColumn';

export default TableIcons;
