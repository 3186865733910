import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'theme';
const useStyles = makeStyles((theme: ITheme) => ({
  powerTableContainer: {
    // typescript doesn't like spreading this mixin, so retyped theme as any
    ...theme.mixins.noScroll,
    height: '100%',
    // prevents horizontal scroll wheel when expand icon is rotating
    overflowX: 'hidden',
  },
}));

export default useStyles;
