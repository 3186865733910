import { SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';

export const CDH = 'cdh';
const CONDITIONS = 'conditions';
const OP_MODES = 'op-modes';

const aTCDHMenu: IMenu['items'] = {
  [CDH]: {
    [TITLE]: 'Command & Control',
    [SUB_ITEMS]: {
      [CONDITIONS]: {
        [TITLE]: 'Conditions',
      },
      [OP_MODES]: {
        [TITLE]: 'Operational Modes',
      },
    },
  },
};
export const wGroupIndicesATCDH = {
  CDH: `${CDH}*`,
  CONDITIONS: `${CDH}.${CONDITIONS}`,
  OP_MODES: `${CDH}.${OP_MODES}`,
};

export default aTCDHMenu;
