/* eslint no-template-curly-in-string: 0 */
import * as Yup from 'yup';
import getThermalProps from 'hooks/getThermalProps';

const { thermalValidation } = getThermalProps();

const validation = Yup.object().shape({
  name: Yup.string()
    .required('A name is required.')
    .max(32, 'Component name must be no more than ${max} characters.'),
  manufacturer: Yup.string().max(32, 'Manufacturer must be no more than ${max} characters.'),
  partNumber: Yup.string().max(32, 'Part number must be no more than ${max} characters.'),
  ...thermalValidation,
});

export default validation;
