import EntitySegment from 'components/general/wizards/EntitySegment';
import { IComponent, ISubsystem } from 'components/general/types/power';
import guidance from './guidance';
import useStyles from './styles';
import ComponentAccordion from './ComponentAccordion';
import SpacecraftDialogButton from 'components/general/SpacecraftDialogButton';
import SpacecraftDialogInlay from 'components/general/SpacecraftDialogInlay';

interface IProps {
  index: string;
  subsystem: ISubsystem;
  editable?: boolean;
  onActionClick?: () => void;
  dialogTabNumber: number;
}

const LoadsSegment = (props: IProps) => {
  const { index, editable, subsystem, onActionClick, dialogTabNumber } = props;
  const classes = useStyles();
  const components = subsystem.components;

  return (
    <>
      <EntitySegment
        title={subsystem.name}
        index={index}
        editable={editable}
        selectedData={subsystem}
        onActionClick={onActionClick}
        guidance={guidance}
      >
        <div className={classes.componentAccordionHeaderRow}>
          <h5>Components</h5>
          <SpacecraftDialogButton
            className={classes.componentAccordionManage}
            tabNumber={dialogTabNumber}
            framed
            borderless
          >
            Manage Components
          </SpacecraftDialogButton>
        </div>
        {/* This div wraps the Accordion elements to enable proper styling of first and last item */}
        <div>
          {components.map((c: IComponent) => (
            <ComponentAccordion component={c} key={c.name} />
          ))}
          {components.length === 0 && (
            <SpacecraftDialogInlay
              className={classes.noComponentInlay}
              dialogTabNumber={dialogTabNumber}
              text="This subsystem currently has no components. Add components in the spacecraftDialog"
            />
          )}
        </div>
      </EntitySegment>
    </>
  );
};
export default LoadsSegment;
