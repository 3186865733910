import { mjd2JcTdb } from 'utils/time';
import { deg2Rad } from 'utils/math';

export const a2Period = (a) => {
  return 2 * Math.PI * Math.pow(Math.pow(a, 3) / 398600, 0.5);
};

export const ae2PerigeeAlt = (a, e) => {
  return a * (1 - e) - 6378;
};

const WGS84_A = 6378.137; // Earth mean equatorial radius, WGS84 [km]

export const computeMoonEphemeris = (mjd) => {
  const jcTdb = mjd2JcTdb(mjd);
  const RAD = deg2Rad(1);

  const lamEc =
    (218.32 +
      481267.8813 * jcTdb +
      6.29 * Math.sin(RAD * (134.9 + 477198.85 * jcTdb)) -
      1.27 * Math.sin(RAD * (259.2 - 413335.38 * jcTdb)) +
      0.66 * Math.sin(RAD * (235.7 + 890534.23 * jcTdb)) +
      0.21 * Math.sin(RAD * (269.9 + 954397.7 * jcTdb)) -
      0.19 * Math.sin(RAD * (357.5 + 35999.05 * jcTdb)) -
      0.11 * Math.sin(RAD * (186.6 + 966404.05 * jcTdb))) *
    RAD;
  const psiEc =
    (5.13 * Math.sin(RAD * (93.3 + 483202.03 * jcTdb)) +
      0.28 * Math.sin(RAD * (228.2 + 960400.87 * jcTdb)) -
      0.28 * Math.sin(RAD * (318.3 + 6003.18 * jcTdb)) -
      0.17 * Math.sin(RAD * (217.6 - 407332.2 * jcTdb))) *
    RAD;
  const p =
    (0.9508 +
      0.0518 * Math.cos(RAD * (134.9 + 477198.85 * jcTdb)) +
      0.0095 * Math.cos(RAD * (259.2 - 413335.38 * jcTdb)) +
      0.0078 * Math.cos(RAD * (235.7 + 890534.23 * jcTdb)) +
      0.0028 * Math.cos(RAD * (269.9 + 954397.7 * jcTdb))) *
    RAD;
  const eps = (23.439291 - 0.0130042 * jcTdb) * RAD;
  const R = (1 / Math.sin(p)) * WGS84_A;

  return [
    R * Math.cos(psiEc) * Math.cos(lamEc),
    R * (Math.cos(eps) * Math.cos(psiEc) * Math.sin(lamEc) - Math.sin(eps) * Math.sin(psiEc)),
    R * (Math.sin(eps) * Math.cos(psiEc) * Math.sin(lamEc) + Math.cos(eps) * Math.sin(psiEc)),
  ];
};
