import styled from 'styled-components';

const StyledDivider = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.palette.text.secondary};
  border: none;
  ${(props) => props.$margin === 'top' && 'margin-bottom: 0'}
  ${(props) => props.$margin === 'bottom' && 'margin-top: 0'}
`;

export default StyledDivider;
