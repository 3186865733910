import { makeGuidance } from 'hooks';
import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';
const useGuidance = makeGuidance({
  _default: {
    heading: 'Create a Custom Component',
    body: [
      {
        chunk:
          'After creation, components can be assigned loads and load states to capture their dynamic electrical loading.',
      },
    ],
  },
  ...componentThermalGuidance,
});

export default useGuidance;
