import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  batteryStateContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  batteryStateArrows: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
