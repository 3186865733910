import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  // allows hoverBar:hover styling to be applied even when hovering over axisTicks and infoLabels
  nonInteractable: {
    pointerEvents: 'none',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
});

export default useStyles;
