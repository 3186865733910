import { useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { ISolarPanel } from 'components/general/types/power';

interface IProps {
  solarPanel: ISolarPanel;
}

const SolarPanelState = (props: IProps) => {
  const { solarPanel } = props;

  const solarViewFactor = useMemo(() => solarPanel.surface.solarViewFactor ?? 0, [solarPanel]);
  // solarViewFactor determines the opacity of the name
  const classes = useStyles({ solarViewFactor });
  return (
    <Tooltip arrow title={solarPanel.name}>
      <div className={classes.solarPanel}>{solarPanel.name}</div>
    </Tooltip>
  );
};

export default SolarPanelState;
