import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },

  spacer: {
    ...theme.mixins.boardElementStyles,
    boxSizing: 'border-box',
  },

  dataAliasingContainer: {
    maxWidth: 650,
    width: '100%',
  },
  dataAliasingMessage: {
    display: 'flex',
    alignItems: 'center',
    height: 200,
    '& h2': {
      textAlign: 'center',
      color: theme.palette.background.inlayText,
    },
  },
  content: {
    backgroundColor: theme.palette.background.main,
    borderRadius: 3,
    boxShadow: theme.shadows[5],
    boxSizing: 'border-box',
    padding: '10px 13px',
    [theme.breakpoints.up('xl')]: {
      padding: '20px 23px',
    },
  },
  contentNarrow: {
    padding: 3,
    [theme.breakpoints.up('xl')]: {
      padding: 3,
    },
  },
  upperArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  widgetSubheader: {
    display: 'flex',
    paddingBottom: '1em',
  },
  loadingWrapper: {
    textAlign: 'center',
  },
  headingSpacer: {
    width: 30,
    display: 'inline-block',
  },
  downloadIcon: {
    alignSelf: 'flex-start',
  },
  downloadIconOutline: {
    fill: theme.palette.background.contrastText,
  },
  widgetAccordion: {
    boxShadow: 'none',
    // these styles fix strange issue where region is smaller than the innerwrapper
    '& .MuiCollapse-wrapperInner': {
      display: 'flex !important;',
      alignItems: 'start !important;',
    },
    // replace this with .MuiAccordion-region after we update Material UI to 5.0+
    '& div[role=region]': {
      width: '100% !important;',
    },
  },
  widgetAccordionCollapseNoHeading: {
    '& .MuiCollapse-wrapper': {
      // add spacing if it's a collapsible widget with no heading
      paddingTop: '1em',
    },
  },
  widgetAccordionSummary: {
    padding: '0 !important;',
    alignItems: 'flex-start', // center the text if widget has no subtitle
    '&.Mui-expanded': {
      // need to explicitly set minHeight here to remove MUI accordion transition and to properly style non collapsible widgets since MUI accordion sets a default minHeight
      minHeight: `40px !important;`,
    },
    '& .MuiAccordionSummary-expandIcon': {
      '& .MuiIconButton-root': {
        color: `${theme.palette.text.primary} !important;`,
      },
      padding: '0px !important;',
    },
  },
  widgetAccordionSummaryNoCollapse: {
    pointerEvents: 'none', // disable pointer if widget is not collapsible
    '&.Mui-expanded': {
      // need to explicitly set minHeight here to remove MUI accordion transition and to properly style non collapsible widgets since MUI accordion sets a default minHeight
      minHeight: `0px !important;`,
    },
    marginBottom: 24,
  },
  widgetAccordionContent: {
    margin: '0px !important;',
    display: 'block !important;',
    '&.Mui-expanded': { margin: '0px !important;' },
  },
  widgetAccordionDetails: {
    display: 'block !important;',
    padding: '0px !important;',
  },
}));

export default useStyles;
