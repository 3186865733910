export function toArrayIfNot<Type>(input: Type | Type[] | undefined | null): Type[] {
  if (input === undefined || input === null) return [];
  if (!Array.isArray(input)) input = [input];
  return input;
}

type BinarySearchOptions = {
  fuzzy?: boolean;
};

/**
 * @param arr - sorted values to search for `key` (assumes no duplicates)
 * @param key - target to search for in `arr`
 * @returns index of `key` if exists in `arr`, else index of closest value `< key`
 */
export function binarySearch<Type>(
  arr: Type[],
  key: Type,
  options: BinarySearchOptions = {}
): number {
  const { fuzzy = false } = options;
  let low = 0;
  let high = arr.length - 1;
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    const midVal = arr[mid];
    if (midVal < key) {
      low = mid + 1;
    } else if (midVal > key) {
      high = mid - 1;
    } else {
      return mid;
    }
  }
  return fuzzy ? high : -1;
}
