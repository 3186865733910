import { makeStyles } from '@material-ui/core/styles';
import WaveDecoration from 'components/general/WaveDecoration';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',

    background:
      'linear-gradient(20deg, ' +
      theme.palette.secondary.light +
      ' -50%, ' +
      theme.palette.primary.dark +
      ' 150%)',
  },
  content: {
    height: '100%',
    width: '100%',
  },
}));

const WaveContainer = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
      <WaveDecoration height={100} />
    </div>
  );
};

export default WaveContainer;
