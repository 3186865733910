import { AccordionDetails, AccordionSummary, Tooltip } from '@material-ui/core';
import useStyles from './styles';
import PowerStateArrow from '../PowerStateArrow';
import { NestedStyledAccordion } from 'components/general/StyledAccordion';
import SolarPanelState from './SolarPanelState';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ISolarArray } from 'components/general/types/power';

interface IProps {
  solarArray: ISolarArray;
}
const SolarArrayGroup = (props: IProps) => {
  const { solarArray } = props;

  const solarPanels = solarArray.panels;

  const classes = useStyles();

  return (
    <div className={classes.solarArrayGroup}>
      <NestedStyledAccordion className={classes.solarArrayAccordion} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            content: classes.solarArrayAccordionContent,
            root: classes.solarArrayAccordionSummary,
            expandIcon: classes.solarArrayExpand,
          }}
        >
          <Tooltip arrow title={solarArray.name}>
            <h3 className={classes.solarArrayName}>{solarArray.name}</h3>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.solarArray }}>
          {solarPanels.map((panel) => (
            <SolarPanelState solarPanel={panel} key={panel.name} />
          ))}
        </AccordionDetails>
      </NestedStyledAccordion>
      <PowerStateArrow type="primary" fill={solarPanels.some((panel) => panel.current)} />
    </div>
  );
};

export default SolarArrayGroup;
