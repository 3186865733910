import { default as MaterialCircularProgress } from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import CreateIcon from '@material-ui/icons/Create';
import useStyles from './styles';
import { ModuleVables } from 'utils/vable';

const CircularProgress = (props) => {
  let { value, loading, symbol, errorMessage, status, ...remainingProps } = props;

  const classes = useStyles({ value, loading, status });
  const { SimulationStatuses: statuses } = ModuleVables;

  let v = value;
  let s;
  if (status === statuses.SUCCEEDED.value) {
    s = <CheckIcon />;
  } else if (
    status === statuses.FAILED.value ||
    status === statuses.MD_FAILURE.value ||
    status === statuses.PARTIAL_RESULTS.value ||
    status === statuses.ERROR.value
  ) {
    s = <PriorityHighIcon />;
    v = 100;
  } else if (status === statuses.OUTDATED.value || status === statuses.TERMINATED.value) {
    s = <ReplayIcon />;
    v = 100;
  } else if (status === statuses.INCOMPLETE.value || status === statuses.READY.value) {
    s = <CreateIcon />;
    v = 100;
  } else {
    s = `${Math.round(value)}%`;
  }

  return (
    <Tooltip
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
      title={
        status === statuses.PENDING.value
          ? 'Your simulation is queued for execution on one of our simulation servers.  Once a node opens up, your simulation will begin.  You should only have to wait a minute or two longer.'
          : status === statuses.RUNNING.value
          ? 'For longer simulations, the period of time between progress percentage increases may be extended.  Please note that your simulation is running, it just hasn’t had time to update us yet.'
          : status === statuses.FAILED.value ||
            status === statuses.MD_FAILURE.value ||
            status === statuses.PARTIAL_RESULTS.value ||
            status === statuses.ERROR.value
          ? errorMessage
          : ''
      }
      arrow
    >
      <Box position="relative" display="inline-flex" className={classes.root}>
        <MaterialCircularProgress
          variant={!loading ? 'determinate' : 'indeterminate'}
          className={classes.progress}
          value={v}
          {...remainingProps}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {!loading && (
            <Typography variant="caption" component="div" className={classes.value}>
              {symbol ? symbol : s}
            </Typography>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default CircularProgress;
