import { useActiveEntities } from 'hooks';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { IBatteryCell } from 'components/general/types/power';
import { IColumn } from 'components/general/types';
import useGuidance from './guidance';
import Dialog from './Dialog';

const batteryCellsColumns: IColumn[] = [
  { title: 'Part Number', field: 'partNumber' },
  {
    title: 'Manufacturer',
    field: 'manufacturer',
  },
];

const BatteryCellsSegment = (props: { index: string }) => {
  const { index } = props;
  const { guidance } = useGuidance();
  const { batteryCells } = useActiveEntities();

  return (
    <EntityTableSegment<IBatteryCell>
      title="Battery Cells"
      index={index}
      guidance={guidance}
      entityColumns={batteryCellsColumns}
      entityData={batteryCells}
      DialogComponent={Dialog}
      modelName="battery cell"
    ></EntityTableSegment>
  );
};

export default BatteryCellsSegment;
