import { useCallback } from 'react';

const useEscapeKeyDown = (callback: () => void) => {
  return useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        callback();
      }
    },
    [callback]
  );
};

export default useEscapeKeyDown;

// pass this hook (with a callback function passed in) directly into the onKeyDown
// prop on any element/component that can recieve that prop. Examples:
//
// onKeyDown={namedFuncNoParams}
//
// onKeyDown={() => setSomeState(false)}
//
// onKeyDown={() => {
//     ...do stuff here
//   }
// }
