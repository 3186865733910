import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    background-color: ${(props) => props.theme.palette.primary.dark}; 
    overflow-y: hidden;
    margin: 0;
    ${(props) => props.theme.typography.body};
  }

  h1 {
    ${(props) => props.theme.typography.h1};
    margin: 0;
  }

  h1.sub {
    ${(props) => props.theme.typography.subh1};
    margin: 0;
  } 

  h2 {
    ${(props) => props.theme.typography.h2};
    margin: 0;
  } 

  h2.sub {
    ${(props) => props.theme.typography.subh2};
    margin: 0;
  }

  h3 {
    ${(props) => props.theme.typography.h3};
    margin: 0;
  }

  h3.sub {
    ${(props) => props.theme.typography.subh3};
    margin: 0;
  }

  h4 {
    ${(props) => props.theme.typography.h4};
    margin: 0;
  }

  h4.sub {
    ${(props) => props.theme.typography.subh4};
    margin: 0;
  }

  h5 {
    ${(props) => props.theme.typography.h5};
    margin: 0;
  }

  h6 {
    ${(props) => props.theme.typography.h6};
    margin: 0;
  }

  p, li {
    ${(props) => props.theme.typography.body};
  }

  p.highlight1 {
    ${(props) => props.theme.typography.highlight1};
  }

  p.highlight2 {
    ${(props) => props.theme.typography.highlight2};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  body,
  p,
  li {
    color: ${(props) => props.theme.palette.background.contrastText};
  }

  @media(max-width: 450px) {
    .grecaptcha-badge {
      visibility: hidden;
    }
  }

  /* Remove arrows from input fields */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export default GlobalStyle;
