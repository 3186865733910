import { createContext, useState, useMemo, useCallback } from 'react';

export const MasonryContext = createContext();

const MasonryProvider = (props) => {
  const { children } = props;
  const [expandedState, setExpandedState] = useState({});

  // utilize activeKey + title to ensure expandedState hashmap has unique keys
  // if the activeKey + title does not exists in expandedState object, return true which functions as the default state
  // activeKey comes from the ContextNavProvider
  const getExpandedState = useCallback(
    (activeKey, title) => expandedState[activeKey + title] ?? true,
    [expandedState]
  );

  const toggleExpandedState = useCallback(
    (activeKey, title) => {
      setExpandedState((prev) => ({
        ...prev,
        [activeKey + title]: !getExpandedState(activeKey, title),
      }));
    },
    [getExpandedState]
  );

  const contextValue = useMemo(() => {
    return { toggleExpandedState, getExpandedState };
  }, [toggleExpandedState, getExpandedState]);

  return <MasonryContext.Provider value={contextValue}>{children}</MasonryContext.Provider>;
};

export default MasonryProvider;
