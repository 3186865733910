import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    ...theme.typography.body,
  },
  menuItemDelete: {
    color: theme.palette.error.light,
  },
}));

export default useStyles;
