import { createContext, useState, useMemo, useCallback } from 'react';
import { useActiveEntities } from 'hooks';

/**
 * Provides utils for using the spacecraft dialog from anywhere.
 * - Includes an object to allow code to reference names of tabs, not the specific tab numbers
 * - Includes config to control the dialog from anywhere,
 *
 * Subscribing to this context implicitly subscribes a component to:
 * - ActiveBranchContext: provides the subsystems to list the tabs
 */
export const SpacecraftContext = createContext();

const SpacecraftProvider = (props) => {
  const { children } = props;

  const { subsystems } = useActiveEntities();

  // Dictionary mapping tab label to tab number
  // e.g. SpacecraftTabs.GEOMETRY = 2 means Geometry is the 2nd panel in the spacecraft dialog
  const SpacecraftTabs = useMemo(() => {
    let res = {
      CAD_MODEL: 0,
      REFERENCE_VECTORS: 1,
      GEOMETRY: 2,
    };
    const topPanelsLength = Object.keys(res).length + 1;
    subsystems.forEach((s, i) => (res[s.category] = topPanelsLength + i));
    return res;
  }, [subsystems]);

  const [spacecraftDialogConfig, setSpacecraftDialogConfig] = useState({
    open: false,
    tabNumber: SpacecraftTabs.CAD_MODEL,
  });

  const closeSpacecraftDialog = useCallback(() => {
    setSpacecraftDialogConfig((prev) => ({ ...prev, open: false }));
  }, [setSpacecraftDialogConfig]);

  const contextValue = useMemo(() => {
    return {
      spacecraftDialogConfig,
      setSpacecraftDialogConfig,
      closeSpacecraftDialog,
      SpacecraftTabs,
    };
  }, [spacecraftDialogConfig, setSpacecraftDialogConfig, closeSpacecraftDialog, SpacecraftTabs]);

  return <SpacecraftContext.Provider value={contextValue}>{children}</SpacecraftContext.Provider>;
};

export default SpacecraftProvider;
