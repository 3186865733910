import { makeGuidance } from 'hooks';

const useGuidance = makeGuidance({
  _default: {
    heading: 'Create and Edit Battery Cells',
    body: [
      {
        chunk:
          'Battery cells are used to assemble the battery packs that will power the satellite. Cells in this table can be swapped in and out to test different cell technologies within your mission architecture.',
      },
    ],
  },
});

export default useGuidance;
