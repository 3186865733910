import { forwardRef, useContext } from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import CircularProgress from 'components/general/CircularProgress';
import { CSVLink } from 'react-csv';
import { toSnakeCase } from 'utils/strings';
import { IconButton, Tooltip, AccordionSummary, AccordionDetails } from '@material-ui/core/';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StyledAccordion from 'components/general/StyledAccordion';
import { ContextNavContext } from 'providers';
import { MasonryContext } from 'providers/MasonryProvider';

const CSVButton = forwardRef((props, ref) => {
  const { dataCache, setDataCache, csvFilename, classes, csvData, title, subtitle } = props;
  return (
    <Tooltip title="Download data as CSV file">
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          // For better load time, we compile CSV data on click, not on page load.
          if (dataCache.length === 0) {
            // Compile data, launch useEffect.
            // To improve load time, csvData should be a callback function that returns array of arrays.
            setDataCache(csvData instanceof Function ? csvData() : csvData);
          } else if (ref.current) ref.current.link.click();
        }}
        className={classes.downloadIcon}
      >
        <SystemUpdateAltIcon className={classes.downloadIconOutline} />
        <CSVLink
          ref={ref}
          style={{ display: 'none' }}
          filename={
            (toSnakeCase(
              csvFilename instanceof Function ? csvFilename(title, subtitle) : csvFilename
            ) || toSnakeCase(subtitle ? title + ' ' + subtitle : title)) + '.csv'
          }
          /*
            data is an array of arrays, where data[0] is an array of column labels, and data[1..n] is actual data.
            [
              ['Label 1', 'Label 2', 'Label 3']
              ['Data 01', 'Data 02', 'Data 03']
              ['Data 11', 'Data 12', 'Data 13']
              .........
            ]
            */
          data={dataCache}
        />
      </IconButton>
    </Tooltip>
  );
});
CSVButton.displayName = 'CSVButton';

const renderHeading = (heading, classes) => {
  if (Array.isArray(heading)) {
    let headingList = [];
    for (let i = 0; i < heading.length; i++) {
      headingList.push(heading[i]);
      if (i < heading.length - 1) {
        headingList.push(<span key={i} className={classes.headingSpacer}></span>);
      }
    }
    return <div className={classes.widgetSubheader}>{headingList}</div>;
  } else {
    return <h2 className={clsx(classes.widgetSubheader, 'sub')}>{heading}</h2>;
  }
};

const Widget = (props) => {
  const {
    title,
    titleAdornment,
    subtitle,
    heading,
    children,
    className,
    loading,
    dataAliasing,
    id,
    collapsibleConfig,
    padding,
    height,
  } = props;

  const classes = useStyles();

  const { expanded, onChange } = collapsibleConfig ?? {};

  const { toggleExpandedState, getExpandedState } = useContext(MasonryContext);

  // if the widget is not wrapped in ContextProvider, use {} to prevent erroring out
  // ContextNavContext activeKey is used to generate unique keys in the MasonryContext to keep track of the expanded state of all widget accordions
  const {
    state: { activeKey },
  } = useContext(ContextNavContext) ?? { state: { activeKey: undefined } };

  const inlineHeight = height ? { height: height } : {};

  return (
    <div
      style={{
        minWidth: props.minWidth ?? 400,
        maxWidth: props.maxWidth ?? '100%',
      }}
      className={clsx(
        classes.root,
        dataAliasing && classes.dataAliasingContainer,
        !dataAliasing && className
      )}
      id={id}
    >
      <div className={classes.spacer} style={inlineHeight}>
        <div
          className={clsx(classes.content, padding === 'narrow' && classes.contentNarrow)}
          style={inlineHeight}
        >
          <StyledAccordion
            expanded={expanded ?? getExpandedState(activeKey, title)} // defaults to true via getExpandedState from MasonryContext
            onChange={onChange ?? (() => toggleExpandedState(activeKey, title))}
            className={clsx(
              classes.widgetAccordion,
              !props.heading &&
                props.subtitle &&
                props.collapsibleConfig &&
                classes.widgetAccordionCollapseNoHeading
            )}
            style={inlineHeight}
          >
            {title && (
              <AccordionSummary
                className={clsx(
                  classes.widgetAccordionSummary,
                  !collapsibleConfig && classes.widgetAccordionSummaryNoCollapse
                )}
                expandIcon={
                  // IconButton wrapper added to utilize hover & click animations
                  collapsibleConfig && (
                    <IconButton>
                      <ExpandMoreIcon />
                    </IconButton>
                  )
                }
                // overwrite CSS of nested MUI accordion components
                classes={{
                  content: classes.widgetAccordionContent,
                }}
              >
                <div className={classes.upperArea}>
                  <div>
                    <div className={classes.title}>
                      <h2>{title}</h2>
                      {titleAdornment}
                    </div>
                    <h2 className="sub">{subtitle}</h2>
                  </div>
                </div>
              </AccordionSummary>
            )}
            <AccordionDetails className={classes.widgetAccordionDetails} style={inlineHeight}>
              {!loading &&
                (dataAliasing ? (
                  <div className={classes.dataAliasingMessage}>
                    <h2>
                      This information cannot be displayed at the current downsampling rate. Please
                      fetch a smaller window.
                    </h2>
                  </div>
                ) : (
                  <>
                    {heading && renderHeading(heading, classes)}
                    {children}
                  </>
                ))}
              {!!loading && (
                <div className={classes.loadingWrapper}>
                  <CircularProgress loading={true} />
                </div>
              )}
            </AccordionDetails>
          </StyledAccordion>
        </div>
      </div>
    </div>
  );
};

export default Widget;
