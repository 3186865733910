import { useRef, useEffect, useState, useContext, useMemo, useCallback } from 'react';
import StyledAccordion from 'components/general/StyledAccordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import StyledDivider from 'components/general/StyledDivider';
import useStyles from './styles';
import StyledButton from 'components/general/StyledButton';
import _ from 'lodash';
import { ContextNavContext } from 'providers';
import StyledAccordionSummary from 'components/general/StyledAccordionSummary';
import clsx from 'clsx';
import GuidanceCard from 'components/general/GuidanceCard';
import Visibility from '@material-ui/icons/Visibility';
import Xray from 'components/general/Xray';

const WizardSegment = (props) => {
  const {
    index,
    title,
    children,
    onSubmit,
    onReset,
    loading,
    disableSubmit,
    editable,
    selectedData,
    onActionClick,
    className,
    guidance,
    xray,
  } = props;

  const segmentRef = useRef(null);
  const [focus, setFocus] = useState(false);
  const [openXray, setOpenXray] = useState(false);
  const classes = useStyles();

  const navContext = useContext(ContextNavContext);

  const { expanded, shouldFocus } = useMemo(() => {
    const item = _.get(navContext.state.items, index.replaceAll('.', '.subItems.'));
    return {
      ...item,
      expanded: !!item?.expanded, // Ensure never undefined so accordion is always controlled
    };
  }, [index, navContext.state.items]);

  const handleScrollAndFocus = () => {
    if (index === navContext.state.activeKey) {
      segmentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      // Toggle focus to bring user's attention to this segment
      setTimeout(() => setFocus(true), 200);
      setTimeout(() => setFocus(false), 1500);
    }
  };

  useEffect(() => {
    if (index === navContext.state.activeKey && shouldFocus) {
      handleScrollAndFocus();
    }
  }, [navContext.state.items]); //eslint-disable-line

  const handleChange = useCallback(
    (event, expanded) => {
      navContext.dispatch({
        type: 'CONTEXT_NAV_ITEM_HANDLE_CLICK',
        key: index,
        allowCollapse: true,
      });
    },
    [index, navContext]
  );

  return (
    <>
      <StyledAccordion
        ref={segmentRef}
        expanded={expanded}
        onChange={handleChange}
        // This was removed while overhualing context nav.
        // It's left here for now in case it becomes relevant again.
        // I am not sure why it was included in the first place.
        // TransitionProps={{
        //   onEntered: handleScrollAndFocus,
        // }}
        $focus={focus}
        className={clsx(className, classes.root)}
      >
        <StyledAccordionSummary
          editable={editable}
          selectedData={selectedData}
          onActionClick={onActionClick}
        >
          <h3>{title}</h3>
        </StyledAccordionSummary>
        <StyledDivider />
        <AccordionDetails>
          <form onSubmit={onSubmit} className={classes.form}>
            {guidance && <GuidanceCard guidance={guidance} />}
            {children}
            <div className={classes.buttonGroup}>
              {xray && (
                <StyledButton
                  tooltip="View as SedaroML"
                  framed
                  type="button"
                  onClick={() => setOpenXray(true)}
                  dontDisableInReadOnly
                >
                  <Visibility />
                </StyledButton>
              )}
              {onReset && (
                <StyledButton framed type="button" onClick={onReset}>
                  Reset
                </StyledButton>
              )}
              {onSubmit && (
                <StyledButton
                  type="submit"
                  loading={loading}
                  disabled={loading || disableSubmit}
                  replaceSpinner
                >
                  Save
                </StyledButton>
              )}
            </div>
          </form>
        </AccordionDetails>
      </StyledAccordion>
      {xray && (
        <Xray title={title || ''} data={xray} open={openXray} onClose={() => setOpenXray(false)} />
      )}
    </>
  );
};

export default WizardSegment;
