import Widget from 'components/general/widgets/Widget';
import useStyles from './styles';
import SolarArrayGroup from './SolarArrayGroup';
import BatteryState from './BatteryState';
import SubsystemGroup from './SubsystemGroup';
import PowerProcessor from './PowerProcessor';
import { useContext } from 'react';
import { MomentContext } from 'providers';

const EPSStateWidget = () => {
  const { model } = useContext(MomentContext);

  const classes = useStyles();

  return (
    <Widget
      title="Electric Power System State"
      subtitle="State of satellite power generation, storage, and distribution"
      minWidth={0}
      collapsibleConfig
    >
      <div className={classes.epsWidgetContainer}>
        <div className={classes.solarArraysContainer}>
          {model.SolarArray.all().map((solarArray) => (
            <SolarArrayGroup solarArray={solarArray} key={solarArray.name} />
          ))}
        </div>
        <PowerProcessor />
        <div className={classes.subsystemsAndBatteryContainer}>
          {model.Subsystem.all().map((subsystem) => (
            <SubsystemGroup key={subsystem.name} subsystem={subsystem} />
          ))}
          <BatteryState />
        </div>
      </div>
    </Widget>
  );
};

export default EPSStateWidget;
