import { useState, useCallback } from 'react';
import { TDialogConfig } from 'components/general/types';

// This TypeHelper allows us to pass a type to TEntityDialogControl to access the type of useEntityDialogControl's return value.
// Building off of a S.O. question, particularly this answer: https://stackoverflow.com/a/64919133
class TypeHelper<T> {
  // Technically using a hook outside a React component or hook, so disable lint about hook rules
  // eslint-disable-next-line
  returnType = useEntityDialogControl<T>();
}
export type TEntityDialogControl<EntityType> = TypeHelper<EntityType>['returnType'];

export default function useEntityDialogControl<EntityType>() {
  // dialogConfig controls dialog opened/closed, action to dispatch, and entity value to be read by the dialog
  const [dialogConfig, setDialogConfig] = useState<TDialogConfig<EntityType>>({ open: false });

  // openDialogForNew is typically used with the plus icon ("+" = onFabClick) on a table and triggers the create dialog
  const openDialogForNew = useCallback(
    () =>
      setDialogConfig({
        open: true,
        action: 'create',
      }),
    [setDialogConfig]
  );

  // openDialogForExisting is typically used with the more menu ("..." = onActionClick) on the right side of the table
  // and triggers the edit/delete menu, which then triggers the respective dialog
  const openDialogForExisting = useCallback(
    (rowData, action) =>
      setDialogConfig({
        open: true,
        action,
        entity: rowData,
      }),
    [setDialogConfig]
  );

  // closeDialog closes the dialog, and leaves the action the same (so it doesn't change appearance as it closes). You
  // may have to extend this with `setDeleteErrorMessage('')` in an onClose function.
  const closeDialog = useCallback(() => {
    setDialogConfig((prev) => ({
      open: false,
      action: prev.action,
    }));
  }, [setDialogConfig]);

  return { dialogConfig, setDialogConfig, openDialogForNew, openDialogForExisting, closeDialog };
}
