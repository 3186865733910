import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import WGroup from 'components/general/WGroup';
import PowerProcessingWizard from './PowerProcessingWizard';

const PowerProcessingEditBoard = () => {
  return (
    <WGroup index={wGroupIndicesATPower.POWER_PP}>
      <PowerProcessingWizard />
    </WGroup>
  );
};

export default PowerProcessingEditBoard;
