import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.background.contrastText,
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    ...theme.typography.highlight1,
    width: '100%',
    ...theme.dimensions.inputs,
    paddingRight: 0,
    border: '1px solid ' + theme.palette.background.contrastText,
    display: 'flex',

    '& input': {
      padding: 0,
    },

    '& .MuiInput-underline::after, .MuiInput-underline::before, .MuiInput-underline:hover:not(.Mui-disabled)::before':
      {
        borderBottom: 'none',
      },

    '& svg': {
      fill: theme.palette.background.contrastText,
    },
  },
}));

export default useStyles;
